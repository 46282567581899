import React from 'react'

// router
import {Navigate} from 'react-router-dom'

type IPrivateProps = {
  children: React.ReactNode
}

const PrivateRoute: any = ({children}: IPrivateProps) => {
    const serial = localStorage.getItem('@serial');

    if (!serial) return <Navigate to="/login" />
    return children
};

export default PrivateRoute