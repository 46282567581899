import React from "react";

// component
import { Skeleton } from "antd";

const title = [1, 2, 3];

export const LoadingReports = () => {
  const loading = [1, 2, 3, 4, 5, 6, 7];
  return (
    <>
      {loading.map((item) => (
        <Reports key={item} />
      ))}
    </>
  );
};

export const LoadingReportCandidates = () => {
  return (
    <>
      {title.map((item) => (
        <ReportCandidate key={item} />
      ))}
    </>
  );
};

export const LoadingReportTitle = () => {
  return (
    <div className="flex justify-between">
      {title.map((item) => (
        <ReportCandidate key={item} />
      ))}
    </div>
  );
};

export const ReportTitle = () => {
  return (
    <div className="flex justify-center border-b border-[#DADADA] text-2xl md:text-3xl py-3 capitalize">
      <Skeleton.Button
        active={true}
        size="large"
        shape="square"
        style={{ width: "300px" }}
      />
    </div>
  );
};

const ReportCandidate = () => {
  return (
    <div className="bg-[#F6F7FB] sm:w-[90%] w-[90%] md:w-[30%] lg:w-[30%] rounded-lg pb-6 px-8 text-center mt-14 mb-6 mx-3">
      <div className="max-w-[230px] m-auto">
        <div className="rounded-full border-[#E9EAEF] border-[6px] w-[122px] h-[122px] text-center object-cover overflow-hidden m-auto mb-3 -mt-14"></div>
      </div>
      <Skeleton.Button
        active={true}
        size="small"
        shape="square"
        style={{ width: "70px" }}
      />
      <div className="flex justify-center mt-4 mb-7">
        <Skeleton.Button active={true} size="large" shape="circle" />
      </div>
      <Skeleton.Button
        active={true}
        size="large"
        shape="square"
        style={{ width: "120px" }}
      />
    </div>
  );
};

// const Report = () => {
//   return <div></div>;
// };

const Reports = () => {
  return (
    <div className="flex items-center justify-between flex-wrap px-4 text-center border border-[#D0D0D0] rounded mb-4">
      <div className="md:w-[15%] lg:w-[15%] w-full lg:border-r md:border-r border-[#D0D0D0] text-[15px] font-[700] py-3 md:py-11 lg:py-11 h-full">
        <Skeleton.Button
          active={true}
          size="large"
          shape="square"
          className="w-full"
        />
      </div>
      <div className="md:w-[70%] lg:w-[70%] w-[100%] my-4">
        <div className="my-3">
          {title.map((item) => (
            <Skeleton.Button
              key={item}
              active={true}
              size="large"
              shape="square"
              className="w-full mr-3"
            />
          ))}
        </div>
        <Skeleton.Button
          active={true}
          size="large"
          shape="square"
          className="w-[50%] mr-3"
        />
      </div>
      <div className="cursor-pointer md:w-[15%] lg:w-[15%] w-full lg:border-l md:border-l border-[#D0D0D0] py-4 pl-4">
        <Skeleton.Button
          active={true}
          size="large"
          shape="square"
          className="w-full mr-3"
        />
      </div>
    </div>
  );
};
