import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import {
  IReportCandidateState,
  IReportReport,
} from "../types/report_candidate";

// Define a type for the slice state

// Define the initial state using that type
const initialState: IReportCandidateState = {
  reports: [],
  loading: false,
};

export const report_candidate = createSlice({
  name: "report_candidate",
  initialState,
  reducers: {
    setReportRole: (state, action: PayloadAction<IReportReport[]>) => {
      state.reports = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setReportRole, setLoading } = report_candidate.actions;

export default report_candidate.reducer;
