import React from "react";

const useUpload = () => {
  const [loading, loadingSet] = React.useState<boolean>(false);
  const onUploadAvatar = () => {
    loadingSet(true);
    loadingSet(false);
  };
  return { loading, onUploadAvatar };
};

export default useUpload;
