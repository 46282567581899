import React from 'react'
// import db from '../../../constants/database';

// component
import { Spin } from 'antd';

type DownloadButtonProps = {
    title: string
    onSubmitHandler: () => void | object
    loading?: boolean
    icon?: React.ReactNode
    className?: string
    disabled?: boolean
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ title, onSubmitHandler, loading, icon, className, disabled }) => {
    return (
        <button onClick={onSubmitHandler} disabled={loading || disabled} className={`text-lg font-abel py-3 px-4 bg-[#ffffff] text-[#243677] rounded-md hover:bg-[#CDD8FE] hover:text-[#243677] hover:border-[#CDD8FE] w-full border-[#243677] shadow-md border font-bold flex justify-center item-center ${className}`}>
            {loading && <Spin />} <span>{title}</span>
        </button>
    )
}

export default DownloadButton