import React from "react";
import { Link } from "react-router-dom";

// components
import JobCard from "../../../components/ui/cards/JobCard";

// state
import { get_jobs } from "../../../services/api/jobs";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import Paginate from '../../../components/ui/paginate/Paginate';
// import { useParams } from 'react-router-dom';

const Jobs = () => {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector(({ jobs }) => jobs.jobs);

  const onLoad = React.useCallback(() => {
    get_jobs(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <div className="flex justify-center items-center px-3 py-3 mt-5 text-[#567BFF] border-b">
        <div className="mx-1 border-r px-2 w-1/12 text-center font-bold">
          S/N
        </div>
        <div className="mx-1 border-r px-2 w-5/12 text-center font-bold">
          Report Title
        </div>
        <div className="mx-1 border-r px-2 w-3/12 text-center font-bold">
          Date
        </div>
        <div className="mx-1 px-2 w-3/12 text-center font-bold">Action</div>
      </div>
      <div className="w-full md:px-5 md:py-4">
        {jobs.map((job, _) => (
          <JobCard key={job.slug} index={_} item={job} />
        ))}
      </div>
      <div className="flex justify-center items-center mt-6">
        <Link to="/jobs">
          <h2 className="text-[#243677] font-bold">View Other Jobs</h2>
        </Link>
      </div>
    </>
  );
};

export default Jobs;
