import React from "react";

type IPaginate = {
  current_page: string;
  last_page: string;
  onNext: () => void;
  onPrev: () => void;
};

const Paginate: React.FC<IPaginate> = ({
  current_page,
  last_page,
  onNext,
  onPrev,
}) => {
  return (
    <div className="w-full flex justify-center items-center py-2 text-[white]">
      <div
        onClick={() => {
          if (Number(current_page) > 1) onPrev();
        }}
        className={`mx-2 py-2 px-8  cursor-pointer border-none rounded-lg ${
          Number(current_page) === 1 ? "bg-[#6F7ECA30]" : "bg-[#243677]"
        }`}
      >
        Prev
      </div>
      <div
        onClick={() => {
          if (Number(current_page) < Number(last_page)) {
            onNext();
          }
        }}
        className={`mx-2 py-2 px-8 bg-[#243677] cursor-pointer border-none rounded-lg ${
          Number(current_page) === Number(last_page)
            ? "bg-[#6F7ECA30]"
            : "bg-[#243677]"
        }`}
      >
        Next
      </div>
    </div>
  );
};

export default Paginate;
