import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

// state
import { get_jobs, get_total_job } from "../../../services/api/jobs";
import { useAppDispatch } from "../../../store/hooks";
import StatsClients from "../misc/StatsClients";
import { get_total_reports } from "../../../services/api/report";
import ReportAvailable from "../../report/misc/ReportAvailable";
import ReportPrevious from "../../report/misc/ReportPrevious";
// import Paginate from '../../../components/ui/paginate/Paginate';

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Available Report",
    children: <ReportAvailable />,
  },
  {
    key: "2",
    label: "Previous Reports",
    children: <ReportPrevious />,
  },
];

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const onLoad = React.useCallback(() => {
    get_total_reports(dispatch);
    get_total_job(dispatch);
    get_jobs(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="md:mx-10 mx-3 my-5 font-abel">
      <h1 className="text-[#243677] text-[24px] mb-3 mt-3">Quick Overview</h1>
      <StatsClients />

      <section className="mt-6">
        <Tabs defaultActiveKey="1" items={items} />

        {/* <Paginate
          current_page={current_page}
          last_page={last_page}
          onNext={() => dispatch(setNextPage())}
          onPrev={() => dispatch(setPrevPage())}
        /> */}
      </section>
    </div>
  );
};

export default Dashboard;
