import React from "react";

// components
import { Layout } from "antd";
import SideBar from "./sidebar";
import ContentComponent from "./content";

type ThemeProps = {
  children?: React.ReactNode;
};

const Theme: React.FC<ThemeProps> = ({ children }) => {
  return (
    <Layout hasSider className="h-[100vh] overflow-hidden">
      <SideBar />
      <Layout style={{ marginLeft: 0 }} className="h-[100vh]">
        <ContentComponent>{children}</ContentComponent>
      </Layout>
    </Layout>
  );
};

export default Theme;
