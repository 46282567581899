import React from "react";

// router
import { Link } from "react-router-dom";

type ISocialLink = {
  icon: string;
  title: string;
  link: string;
};

const SocialLink: React.FC<ISocialLink> = ({ title, icon, link }) => {
  return (
    <Link to={link} className="text-center mx-2" target="_blank">
      <img src={icon} alt={title} className="h-[31px] w-[31px] m-auto" />
      <div className="text-[12px] text-[#243677] text-center underline mt-2">
        {title}
      </div>
    </Link>
  );
};

export default SocialLink;
