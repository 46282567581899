import React from "react";
import { FaMoneyBillWave, FaUserPlus, FaUsers } from "react-icons/fa";
import { BiCartAlt, BiShareAlt } from "react-icons/bi";
import { CiReceipt } from "react-icons/ci";
import { GoHome } from "react-icons/go";
import { IoDocumentAttach } from "react-icons/io5";

export type ItemType = {
  label: string;
  icon: React.ReactNode;
  link: string;
  key: string | number;
  children?: ItemType[];
};

export const items: ItemType[] = [
  {
    label: "Dashboard",
    icon: React.createElement(GoHome),
    link: "/",
    key: "/",
  },
  {
    label: "Reports",
    icon: React.createElement(CiReceipt),
    key: "/reports",
    link: "/reports",
  },
  {
    label: "Jobs/Orders",
    icon: React.createElement(BiCartAlt),
    key: "/jobs",
    link: "/jobs",
  },
  {
    label: "Invoices",
    icon: React.createElement(FaMoneyBillWave),
    key: "/invoices",
    link: "/invoices",
  },
  {
    label: "Outsourcing",
    icon: React.createElement(FaUserPlus),
    key: "/outsourcing",
    link: "/outsourcing",
  },
  {
    label: "Candidate Management",
    icon: React.createElement(FaUsers),
    key: "/candidate-management",
    link: "/candidate-management",
  },
  {
    label: "Agreements",
    icon: React.createElement(IoDocumentAttach),
    key: "/agreements",
    link: "/agreements",
  },
  {
    label: "Shared Documents",
    icon: React.createElement(BiShareAlt),
    key: "/shared-documents",
    link: "/shared-documents",
  },
  // {
  //     label: 'Support',
  //     icon: React.createElement(BiSupport),
  //     key: '/support',
  //     link: '/support'
  // },
  // {
  //     label: 'Settings',
  //     icon: React.createElement(CiSettings),
  //     key: '/settings',
  //     link: '/settings'
  // },
  //   {
  //     label: "Settings",
  //     icon: React.createElement(CiSettings),
  //     key: "/settings",
  //     link: "/settings",
  //   },
  //   {
  //     label: "Logout",
  //     icon: React.createElement(BiLogOut),
  //     key: "/logout",
  //     link: "/logout",
  //   },
];
