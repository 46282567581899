import React from 'react'
import useChangePassword from '../hooks/useChangePassword'

// components
import TextInput from '../../../components/elements/forms/TextInput'
import ChangePasswordToken from '../misc/ChangePasswordToken';

// theme
import Landing from '../app'

// assets
import SubmitButton from '../../../components/elements/forms/SubmitButton';
import { Link, useNavigate } from 'react-router-dom';

// types and state
import {change_password} from '../../../services/api/auth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loading = useAppSelector(({ loading }) => loading?.loading);
  const {err, errSet, data, dataSet, visible, visibleSet, onValidate} = useChangePassword();

  const onSubmitHandler = async () => {
    onValidate();
  };

  const onLoad = React.useCallback(() => {
    const email = localStorage.getItem('@email');
    if (!email) {
      navigate('/forgot-password');
    } else {
      dataSet(prev => ({...prev, email}))
    }
  }, [dataSet, navigate]);

  React.useEffect(() => {
    onLoad()
  }, [onLoad]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    dataSet(prev => ({...prev, [name]: value}))
    errSet(prev => ({...prev, [name]: ''}))
  }

  return (
    <Landing>
      <div className="md:flex font-abel md:py-10 md:flex-row bg-[#EFF2FE] md:justify-center md:items-center">
        <div className='bg-[white] px-4 py-10 items-center md:mx-5 max-w-[695px] border-none rounded-lg md:px-12 md:pt-12 md:pb-16 md:my-12'>
          <div className='text-left w-full my-5'>
            <h3 className='md:text-[30px] text-[23px] font-[900]'>Welcome back to 25th &amp; Staffing</h3>
            <span className='text-lg font-[700] text-[#aaaaaa]'>Sign In to continue</span>
          </div>

          <p className='text-lg font-[700] text-[#000000] text-opacity-60'>{data.email}</p>
          <TextInput type='password' placeholder='Password' container='my-3' status={err.password} name="password" value={data.password} onChange={onChange} />

          <TextInput type='password' placeholder='Confirm Password' container='my-3' status={err.password_confirmation} name="password_confirmation" value={data.password_confirmation} onChange={onChange} />

          <div className='w-[100%] flex items-center mt-7 align-center'>
              <SubmitButton loading={loading} title='SIGN IN' onSubmitHandler={onSubmitHandler} className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]" />
          </div>

          <div className='text-center mt-6'>
            <span>Don&apos;t have an account? <Link to="/login" className='text-[#243677]'> Sign In</Link></span>
          </div>

        </div>
      </div>
      <ChangePasswordToken 
        visible={visible} 
        onCancel={async res => {
          if (res) {
            dataSet(prev => ({...prev, token: res}))
            change_password(dispatch, {...data, token: res}, navigate);
          }
          visibleSet(false);
        }} 
      />
    </Landing>
  )
}

export default ChangePassword