import React from "react";
import useUpload from "../hooks/useUpload";

// components
// import CreateModal from "../misc/CreateModal";
import InputUpdate from "../misc/InputUpdate";
import SelectUpdate from "../misc/SelectUpdate";
import { Spin } from "antd";

// router
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// state
import {
  get_full_profile,
  update_address,
  update_full_name,
  update_phone,
  update_staff_size,
  update_state,
  update_password,
  update_company_name,
} from "../../../services/api/profile";

// react router
import {
  get_countries,
  get_industries,
  get_states,
} from "../../../services/api/misc";
import CreateModal from "../misc/CreateModal";

const UpdateClient = () => {
  const dispatch = useAppDispatch();
  const client = useAppSelector(({ auth }) => auth.user);
  const countries = useAppSelector(({ misc }) => misc.countries);
  const states = useAppSelector(({ misc }) => misc.states);
  const [show, showSet] = React.useState<boolean>(false);
  const { onUploadAvatar, loading } = useUpload();

  const onLoad = React.useCallback(() => {
    get_industries(dispatch);
    get_full_profile(dispatch);
    get_countries(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  console.log(countries);

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white] px-10 py-4">
      <div className="flex items-center mb-4">
        <div className="rounded-full border-[#4E4E4E] border-[2px] w-[132px] h-[132px] text-center flex items-center justify-center overflow-hidden md:mr-5 relative">
          {loading && <Spin size="large" className="absolute" />}
          <img
            src={client?.profile?.avatar}
            alt={client?.profile?.organization_name}
            className="h-[100%] w-full rounded-full object-cover p-1"
          />
        </div>
        <div
          onClick={() => document.getElementById("avatar")?.click()}
          className="border border-[#4E4E4E] h-[46.5px] w-[150px] px-2 ml-2 flex justify-center items-center cursor-pointer"
        >
          <input
            hidden
            type="file"
            id="avatar"
            name="avatar"
            onChange={onUploadAvatar}
          />
          <p className="text-[17px]">Add profile picture</p>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="mb-5 w-[48%]">
          <InputUpdate
            label="Company Name"
            name={client?.profile?.organization_name || ""}
            onSubmit={(res) => update_company_name(dispatch, res)}
          />
        </div>

        <div className="mb-5 w-[48%]">
          <InputUpdate
            label="Representative Name"
            name={client?.profile?.full_name || ""}
            onSubmit={(res) => update_full_name(dispatch, res)}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <div className="mb-5 w-[48%]">
          <InputUpdate
            label="Office Address"
            name={client?.profile?.address || ""}
            onSubmit={(res) => update_address(dispatch, res)}
          />
        </div>

        <div className="mb-5 w-[48%]">
          <InputUpdate
            label="Phone Number"
            name={client?.profile?.phone || ""}
            onSubmit={(res) => update_phone(dispatch, res)}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <div className="mb-5 w-[48%]">
          <SelectUpdate
            label="Country"
            items={countries}
            name={client?.profile?.state?.country?.id || ""}
            selected={client?.profile?.state?.country?.id || ""}
            onSelect={(res) => get_states(res.name, dispatch)}
          />
        </div>

        <div className="mb-5 w-[48%]">
          <SelectUpdate
            items={states}
            label="State"
            name={client?.profile?.state?.id || ""}
            selected={client?.profile?.state?.id || ""}
            onSelect={(res) => update_state(dispatch, res)}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <div className="mb-5 w-[48%]">
          <InputUpdate
            label="Staff Size"
            name={client?.profile?.staff_size || ""}
            onSubmit={(res) => update_staff_size(dispatch, res)}
          />
        </div>
        <div className="mb-5 w-[48%]">
          <label className="text-[#A4A4A4]">Industry</label>
          <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
            {client?.profile?.industries?.map((_item, index) => (
              <p className="text-[#707070] px-4 capitalize" key={index + 1}>
                {/* {client?.profile?.address} */}
              </p>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="flex justify-between">
        <div className="mb-5 w-[100%]">
          <label className="text-[#333333] text-sm pb-0">
            Additional Information
          </label>
          <TextInputBox name="comment" value={""} placeholder="" />
        </div>
      </div> */}

      <CreateModal
        title="Change Password"
        handleOk={(res) => {
          if (res.password && res.password_confirmation) {
            update_password(dispatch, res);
          }
          showSet(false);
        }}
        visible={show}
      />
    </div>
  );
};

export default UpdateClient;
