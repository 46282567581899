import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// types and state
import {
  setRoles,
  setReligions,
  setMaritalStatus,
  setGenders,
  setStates,
  setCountries,
} from "../../store/slices/misc";
import { setLoading } from "../../store/slices/loading";
import { setNotification } from "../../store/slices/navbar";

type ISetRoles = (dispatch: any) => Promise<void>;
export const get_roles: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-roles");

    dispatch(setRoles(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_religions: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-religions");

    dispatch(setReligions(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_marital_statuses: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-marital-status");

    dispatch(setMaritalStatus(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_gender: ISetRoles = async (dispatch: Function) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-genders");

    dispatch(setGenders(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_notification: ISetRoles = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-notifications");

    dispatch(setNotification(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const get_industries: ISetRoles = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-industries");

    dispatch(setNotification(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const get_countries = async (dispatch: any) => {
  try {
    const response = await axiosInstance.get("/get-countries");

    dispatch(setCountries(response.data));
  } catch (err) {
    if (isAxiosError(err)) {
    }
  }
};

export const get_states = async (id: string, dispatch: any) => {
  try {
    const response = await axiosInstance.get(`/get-states/${id}`);

    dispatch(setStates(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};
