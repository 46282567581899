
import React from 'react'
import useRegister from '../hooks/useRegister'
import SubmitButton from '../../../components/elements/forms/SubmitButton'

// components
import TextInput from '../../../components/elements/forms/TextInput'

// constants and images
// import register from '../../../assets/images/register/organization.png';

// theme
import Landing from '../app'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';

const Register = () => {
  const loading = useAppSelector(({ loading }) => loading.loading)
  const { user, userErr, onChangeHandlerUser, onSubmitUser } = useRegister()

  return (
    <Landing className='w-full'>
      <div className=" md:py-3 flex w-full bg-[#EFF2FE] justify-center items-center h-6/6">
        <div className='bg-[white] px-4 py-10 items-center md:mx-5 max-w-[695px] border-none rounded-lg md:px-12 md:pt-12 md:pb-16 md:my-12'>

          <div className='flex my-2'>
            {/* <div className='hidden md:w-4/12 md:flex md:justify-center md:items-center mx-2'>
              <Link to="https://organization.25thandstaffing.com/register">
                <div className='flex flex-col items-center text-center justify-center bg-[#DDDDDD] bg-opacity-75 cursor-pointer py-7 px-10'>
                  <img src={register} alt="Organization Register" className='h-[70px] w-[70p] mb-3' />
                  <p className='bg-[#243677] text-[#ffffff] px-4 py-2 mb-3 rounded-sm'>An employer</p>
                  <p className='text-[#303030] text-[16px]'>I want to hire an</p>
                  <p className='text-[#303030] text-[16px]'>exceptional Talent.</p>
                </div>
              </Link>
            </div> */}

            <div className='border-none' />

            <div className='md:w-[100%] flex justify-center items-center px-2'>
              <div className=' w-full'>
                <div className=' w-full my-4'>
                  <h3 className='text-[23px] font-bold text-left md:text-[30px]'>Sign up with us at 25th &amp; Staffing</h3>
                  <span className='text-lg font-[700] text-[#aaaaaa]'>Thank you for choosing 25th & Staffing. To get started, kindly sign-up</span>
                </div>

                <div className='w-full mb-3'>
                  <TextInput status={userErr.first_name} placeholder='First Name' name="first_name" value={user.first_name} onChange={onChangeHandlerUser} />
                </div>

                <div className='w-full mb-3'>
                  <TextInput status={userErr.last_name} placeholder='Last Name' name="last_name" value={user.last_name} onChange={onChangeHandlerUser} />
                </div>

                <div className='w-full my-3'>
                  <TextInput status={userErr.email} placeholder='Email address' name="email" value={user.email} onChange={onChangeHandlerUser} />
                </div>

                <div className='w-full my-3'>
                  <TextInput type='password' status={userErr.password} placeholder='Password' name="password" value={user.password} onChange={onChangeHandlerUser} />
                </div>
                <div className='w-full my-3'>
                  <TextInput type='password' status={userErr.password_confirmation} placeholder='Confirm password' name="password_confirmation" value={user.password_confirmation} onChange={onChangeHandlerUser} />
                </div>

                <div className='w-full my-2 text-center mt-5'>
                  <SubmitButton title="Register" loading={loading} onSubmitHandler={onSubmitUser} className="md:w-[100%]" />
                </div>

                <div className='mt-5 mb-10'>
                  <span>Already have an account?<Link to="/login" className='text-[#243677]'> Sign In</Link></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Landing>
  )
}

export default Register