import React from "react";
import { Drawer } from "antd";

import SubmitButton from "../../../components/elements/forms/SubmitButton";
// navigate
import { useAppSelector } from "../../../store/hooks";
import Applicant from "../../jobs/applicant";
import { ICandidateEducation } from "../../../store/types/candidate";

const Candidates = () => {
  const [userID, userIDSet] = React.useState<string>("");
  const candidates = useAppSelector(({ report }) => report.report_candidates);
  const report_stat = useAppSelector(({ report }) => report.report_stat);

  const [open, setOpen] = React.useState(false);

  const showDrawer = (item: string) => {
    userIDSet(item);
    setOpen(true);
  };

  const filter = (data: ICandidateEducation[]): string => {
    const highest = data.find((item) => item.highest);
    if (highest) return highest?.degree?.name;
    return "";
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="font-abel px-5">
      <p className="capitalize text-[22px] font-bold text-[#243677] mb-2">
        Here are a few of the characteristics we noticed in the prospects:
      </p>
      {report_stat?.candidates_characteristics && (
        <div
          className="text-[16px] text-[#4E4E4E] mb-5"
          dangerouslySetInnerHTML={{
            __html: report_stat?.candidates_characteristics,
          }}
        />
      )}

      <table className="text-[14px] text-[#4E4E4E] mb-5 text-center">
        <tr className="text-center">
          <th>Name</th>
          <th>Position</th>
          <th>Education</th>
          <th>Experience</th>
          <th>Certification</th>
          <th>Action</th>
        </tr>
        {candidates?.map((order) =>
          order?.candidates?.map((candidate, index) => (
            <tr key={index}>
              <td className="capitalize">
                {candidate?.user?.profile?.first_name +
                  " " +
                  candidate?.user?.profile?.last_name}
              </td>
              <td className="capitalize">{order?.order?.position?.name}</td>
              <td className="capitalize">
                {/* {candidate?.user?.profile?.address?.about?.education_level
                  ?.name || ""} */}
                {candidate?.user &&
                candidate?.user?.profile &&
                candidate?.user?.profile?.educations &&
                candidate?.user?.profile?.educations.length
                  ? filter(candidate?.user?.profile?.educations)
                  : ""}
              </td>
              <td className="lowercase">
                {candidate?.user?.profile?.address?.about
                  ?.years_of_experience &&
                  candidate?.user?.profile?.address?.about
                    ?.years_of_experience + " year(s)"}
              </td>
              <td className="capitalize">
                {candidate?.user?.profile?.educations?.length}
              </td>
              <td className="text-center ml-auto mr-auto">
                <div className="w-[150px]">
                  <SubmitButton
                    title="View Profile"
                    onSubmitHandler={() => {
                      if (candidate?.id) showDrawer(candidate?.id);
                    }}
                  />
                </div>
              </td>
            </tr>
          ))
        )}
      </table>
      <Drawer placement="right" onClose={onClose} open={open} width="85%">
        <Applicant id={userID} />
      </Drawer>
    </div>
  );
};

export default Candidates;
