import React from 'react';

import {useNavigate} from 'react-router-dom';
import { auth } from '../../../../services/api/auth';
import { ILoginError, ILogin } from '../../../../store/types/auth';
import { useAppDispatch } from '../../../../store/hooks';

const useLogin = () => {
    const history = useNavigate();
    const dispatch = useAppDispatch();
    const serial = localStorage.getItem('@serial')

    const [data, dataSet] = React.useState<ILogin>({email: '', password: ''});
    const [error, errorSet] = React.useState<ILoginError>({email: '', password: ''});

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target
        dataSet(prev => ({...prev, [name]: value}));
        errorSet(prev => ({...prev, [name]: ''}));
    };

    const onValidate: () => boolean = () => {
      let err = true;
      if (!data.email || data.email.length <= 3) {
        err = false
        errorSet(prev => ({...prev, email: 'warning'}))
      }
      if (!data.password) {
        err = false;
        errorSet(prev => ({...prev, password: 'warning'}))
      }
      return err;
    }

  const onSubmitHandler = async () => {
      const validate = onValidate()
      if (validate) {
          auth(data, dispatch, history)
      }
  }

  React.useEffect(() => {
    if (serial) {
      history('/');
    }
  }, [history, serial]);

  return {data, error, dataSet, onChangeHandler, onSubmitHandler}
}

export default useLogin;