// chart
import { VictoryChart, VictoryBar, VictoryTheme, VictoryPie } from "victory";
import { useAppSelector } from "../../../store/hooks";

const Demography = () => {
  const gender_stats = useAppSelector(({ report }) => report.gender_stats);
  const location_stats = useAppSelector(({ report }) => report.loc_stat);

  return (
    <div className="font-abel px-5">
      <p className="capitalize text-[22px] mb-3 font-bold text-[#243677]">
        Candidates Demography
      </p>
      <p className="text-[16px] text-[#4E4E4E]">Location</p>
      <p className="text-[16px] text-[#4E4E4E] capitalize mb-5">
        {location_stats.map((loc, index) => (
          <div key={loc.x}>
            {loc.y} Candidates were sourced from{" "}
            {index + 1 === location_stats.length ? loc.x + "." : loc.x + ","}
          </div>
        ))}
      </p>
      <VictoryPie
        data={location_stats}
        colorScale={["#243677", "#C3D0FF", "#CDD8FE", "#F6F7FB", "#FFFFFF"]}
        height={250}
        style={{ data: { fontFamily: "Abel", fontStyle: "capitalize", fontWeight: "normal", fontSize: 16} }}
        animate={{
          duration: 2000,
        }}
      />
      <p className="text-[16px] text-[#4E4E4E]">Gender </p>
      <p className="text-[16px] text-[#4E4E4E] capitalize">
        {gender_stats.map((item) => `${item?.x}: ${item?.y} -- `)}
      </p>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={20}
        height={200}
      >
        <VictoryBar
          style={{
            data: {
              fill: "#243677",
              fontFamily: "Abel",
              fontWeight: "normal",
              fontStyle: "capitalize",
              fontSize: 16
            },
          }}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
          data={gender_stats}
        />
      </VictoryChart>
    </div>
  );
};

export default Demography;
