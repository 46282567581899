import React from 'react'
import { IChangePasswordData, IChangePasswordError } from '../../../store/types/auth';

const useChangePassword = () => {
  const [visible, visibleSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IChangePasswordData>({
    email: "",
    password: "",
    password_confirmation: "",
    token: ""
  });
  const [err, errSet] = React.useState<IChangePasswordError>({
    email: "",
    password: "",
    password_confirmation: "",
    token: ""
  });

  const onValidate = () => {
    let err: boolean = false;
    if (!data.email) {
      err = true
    }
    if (!data.password) {
      err = true
      errSet(prev => ({...prev, password: 'warning'}))
    }
    if (data.password !== data.password_confirmation) {
      err = true
      errSet(prev => ({...prev, password: 'warning', password_confirmation: 'warning'}))
    }
    return err ? false : visibleSet(true);
  }

  return {err, errSet, data, dataSet, visible, visibleSet, onValidate};
}

export default useChangePassword