import React from "react";

// location router
import { useLocation, useNavigate, Link } from "react-router-dom";

// constants and icons
import crest from "../../assets/images/icons/white_logo.png";
import { BiLogOut, BiSupport } from "react-icons/bi";
import avatar from "../../assets/images/temporary/user.png";

// components
import { Layout, Menu } from "antd";
import { ItemType } from "./items";
import { items } from "./items";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logout } from "../../services/api/auth";
import { CiSettings } from "react-icons/ci";

const { Sider } = Layout;

const SideBar: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useAppSelector(({ auth }) => auth.user);
  const user_avatar = user?.profile?.avatar || avatar;

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => null}
      onCollapse={(collapsed, type) => null}
      className="site-layout-background bg-[#243677] h-[100vh] font-abel overflow-hidden"
    >
      <div className=" w-full flex items-center justify-center py-[25px] bg-[#243677]">
        <img src={crest} alt="25th and Staffing Crest" className="w-[113px]" />
      </div>
      <Menu
        mode="inline"
        defaultOpenKeys={[pathname]}
        defaultSelectedKeys={[pathname]}
        className="justify-between"
        style={{
          height: "100%",
          borderRight: 2,
          backgroundColor: "#243677",
          color: "white",
        }}
      >
        {items.map(
          (item: ItemType): React.ReactNode => (
            <Menu.Item
              icon={item.icon}
              key={item.key}
              className="my-5 font-abel"
            >
              <Link to={item.link}>{item.label}</Link>
            </Menu.Item>
          )
        )}
        <div className="fixed bottom-0 pb-4 pl-[24px]">
          <Menu.Item key="/support" icon={<BiSupport />} className="font-abel">
            <p>Support</p>
          </Menu.Item>
          <Menu.Item
            key="/setting"
            icon={<CiSettings size={18} />}
            className="font-abel"
          >
            <p>Settings</p>
          </Menu.Item>

          <div className="flex justify-between items-center w-[160px] pt-5">
            <Link to="/profile" className="flex items-center cursor-pointer">
              <img
                src={user_avatar}
                alt="User Avatar"
                className="h-[32px] w-[32px] rounded-full"
              />
              <p className="font-abel text-[14px] ml-2 mr-5">Admin</p>
            </Link>
            <div className="ml-auto cursor-pointer">
              <BiLogOut size={20} onClick={() => logout(dispatch, navigate)} />
            </div>
          </div>
        </div>
      </Menu>
    </Sider>
  );
};

export default SideBar;
