import React, { useState } from "react";

// components
import Overview from "../misc/Overview";
import Purpose from "../misc/Purpose";
import Candidates from "../misc/Candidates";
import Results from "../misc/Results";
import Demography from "../misc/Demography";
import Limitations from "../misc/Limitations";
import SubmitButton from "../../../components/elements/forms/SubmitButton";

// router
import { Link, useNavigate, useParams } from "react-router-dom";

// state
import { useAppDispatch } from "../../../store/hooks";
import {
  get_report_candidates,
  get_report_gender_stats,
  get_report_loc_stats,
  get_report_roles,
  get_report_stats,
} from "../../../services/api/report";

// constants and icons
import { BiArrowBack } from "react-icons/bi";

const steps = [
  {
    title: "Overview",
    content: <Overview />,
  },
  {
    title: "Our Purpose",
    content: <Purpose />,
  },
  {
    title: "Candidates",
    content: <Candidates />,
  },
  {
    title: "Results",
    content: <Results />,
  },
  {
    title: "Demography",
    content: <Demography />,
  },
  {
    title: "Limitations",
    content: <Limitations />,
  },
];

const ReportOverviewView: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const onLoad = React.useCallback(() => {
    if (id) {
      get_report_stats(dispatch, id);
      get_report_candidates(dispatch, id);
      get_report_roles(dispatch, id);
      get_report_gender_stats(dispatch, id);
      get_report_loc_stats(dispatch, id);
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="bg-[white] font-abel">
      <div className="text-[#ffffff] bg-[#243677] text-lg md:text-3xl text-center py-4 px-4 font-abel flex justify-between items-center">
        <Link to="/reports" className="mr-2 flex items-center">
          <BiArrowBack size={25} color="white" />
          <span className="ml-2 md:text-[15px] text-[11px] leading-3">
            Back to list
          </span>
        </Link>
        <span>Successful Applicants</span>
        <div className="md:w-[103px] w-[50px] ml-2"></div>
      </div>
      <div className="px-5 pt-3 pb-24 bg-[white]">{steps[current].content}</div>

      <div className="flex justify-end mt-8 fixed bottom-5 right-10 ml-auto mr-auto w-100">
        {current > 0 && (
          <div className="w-[150px]">
            <SubmitButton title="Back" onSubmitHandler={() => prev()} />
          </div>
        )}
        {current === steps.length - 1 && id && (
          <div className="w-[150px] ml-3">
            <SubmitButton
              title="Done"
              onSubmitHandler={() => navigate(`/report/${id}`)}
            />
          </div>
        )}

        {current < steps.length - 1 && (
          <div className="w-[150px] ml-3">
            <SubmitButton title="Next" onSubmitHandler={() => next()} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportOverviewView;
