import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { get_reports_orders } from "../../../services/api/report_candidate";
import UserData from "./UserData";
import { LoadingReportTitle } from "./LoadingReports";

type IReportJob = {
  id: string;
};

const ReportJob: React.FC<IReportJob> = ({ id }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    ({ report_candidate }) => report_candidate.loading
  );
  const roles = useAppSelector(
    ({ report_candidate }) => report_candidate.reports
  );
  const onLoad = React.useCallback(() => {
    if (id) get_reports_orders(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full">
      {loading ? (
        <LoadingReportTitle />
      ) : (
        roles.map((item) => (
          <div className="w-full" key={item?.id}>
            <h1 className="text-2xl text-center text-[#243677] border-t border-b border-[#DADADA] py-3 mb-4 capitalize font-[700]">
              {item?.order?.position?.name}
            </h1>

            <div className="flex flex-wrap justify-center pl-7">
              <UserData id={item?.id} />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ReportJob;
