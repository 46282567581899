// request
import { AxiosError, isAxiosError } from "axios";

// notification
import { errorNotification } from "../components/ui/alerts";

const handler = (error: AxiosError | Error | unknown) => {
  if (isAxiosError(error)) {
    errorNotification({
      status: "error",
      message: error?.response?.data?.message,
    });
  } else {
    errorNotification({
      status: "error",
      message: "Please check your Internet.",
    });
  }
};

export default handler;
