import React from "react";

//
import DownloadButton from "../../../components/elements/forms/DownloadButton";

// constants
// import db from '../../../constants/database';

// navigate
import { useNavigate, useParams } from "react-router-dom";

// state and store
import { get_applicants } from "../../../services/api/applicants";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Paginate from "../../../components/ui/paginate/Paginate";
import { setNextPage, setPrevPage } from "../../../store/slices/applicant";

const Applicants: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const current_page = useAppSelector(
    ({ applicant }) => applicant.current_page
  );
  const last_page = useAppSelector(({ applicant }) => applicant.last_page);
  // const applicants = useAppSelector(({ applicant }) => applicant.applicants);

  const onLoad = React.useCallback(() => {
    if (id) {
      get_applicants(dispatch, id, current_page);
    }
  }, [current_page, dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel w-full text-center text-[#4E4E4E] bg-[white]">
      <div className="text-[#ffffff] bg-[#243677] text-2xl md:text-4xl py-4 font-abel">
        Successful Applicants
      </div>
      <div className="px-5 py-4 "></div>
      <h1 className="text-2xl md:text-4xl text-[#243677] mb-8">Accounting</h1>
      {/* <h1 className='text-3xl md:text-4xl text-[#243677] mb-8'>{item?.position?.name}</h1> */}
      <div className="flex flex-wrap justify-center">
        {/* {applicants?.map((item, i) => (
          <UserData key={i} item={item} />
        ))} */}
      </div>

      <Paginate
        current_page={current_page?.toString()}
        last_page={last_page?.toString()}
        onNext={() => dispatch(setNextPage())}
        onPrev={() => dispatch(setPrevPage())}
      />

      <div className="flex justify-center mt-4 mb-8">
        <DownloadButton
          title="View Report"
          onSubmitHandler={() => navigate(`/`)}
          className="mb-3 max-w-[150px]"
        />
      </div>
    </div>
  );
};

export default Applicants;
