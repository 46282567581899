import { useAppSelector } from "../../../store/hooks";

const Purpose = () => {
  const report_stat = useAppSelector(({ report }) => report.report_stat);
  return (
    <div className="font-abel px-5 w-full">
      <p className="capitalize text-[22px] mb-2 font-bold text-[#243677]">
        Our Overview
      </p>
      {report_stat?.overview && (
        <div
          className="text-[16px] w-full"
          dangerouslySetInnerHTML={{ __html: report_stat?.overview }}
        />
      )}

      <p className="capitalize text-[22px] mb-2 mt-4 font-bold text-[#243677]">
        Our Purpose
      </p>
      {report_stat?.purpose && (
        <div
          className="text-[16px] w-full"
          dangerouslySetInnerHTML={{ __html: report_stat?.purpose }}
        />
      )}

      <p className="capitalize text-[22px] mb-2 mt-4 font-bold text-[#243677]">
        General Summary
      </p>
      {report_stat?.summary && (
        <div
          className="text-[16px] w-full"
          dangerouslySetInnerHTML={{ __html: report_stat?.summary }}
        />
      )}
    </div>
  );
};

export default Purpose;
