export const colors = {};

export const gen_color = [
  "#0ced82",
  "#00c76b",
  "#076877",
  "#038735",
  "#0ae10c",
  "#095120",
  "#036179",
  "#099fc9",
  "#0b88f9",
  "#0632bb",
  "#04ed70",
  "#0731c2",
  "#0e4c2a",
  "#084944",
  "#013a52",
  "#0143e0",
  "#01274a",
  "#04c87f",
  "#0b17c8",
  "#0b8854",
  "#01aca4",
  "#02bea7",
  "#0b801f",
  "#01de51",
  "#0392de",
  "#093471",
  "#0dd0ba",
  "#09b162",
  "#097fae",
  "#07a456",
  "#0ab75a",
  "#02d32a",
  "#021dd7",
  "#0c9c28",
  "#081041",
  "#0d49a8",
  "#080a52",
  "#08a762",
  "#0054d9",
  "#033b8b",
  "#09ce2f",
  "#055dce",
  "#01a191",
  "#02195b",
  "#0f29cb",
  "#04b03c",
  "#06e111",
  "#0ba2a0",
  "#04237c",
  "#062685",
  "#0b7740",
  "#013646",
  "#07eb5b",
  "#00298c",
  "#0d535c",
  "#0551bb",
  "#0e782e",
  "#06d154",
  "#0599c5",
  "#0c40d2",
  "#009f4c",
  "#02e36a",
  "#003103",
  "#02c400",
  "#05c078",
  "#0c0a4c",
  "#06b629",
  "#0eb451",
  "#0c32d8",
  "#0ac307",
  "#0b3be6",
  "#08512f",
  "#0810c6",
  "#06c37c",
  "#0463fb",
  "#04bed0",
  "#01344b",
  "#004480",
  "#027f76",
  "#058f94",
  "#02734b",
  "#009610",
  "#0e64da",
  "#072a35",
  "#032dc6",
  "#0b0e57",
  "#0c81a9",
  "#0c2b21",
  "#07b3df",
  "#09b90f",
  "#0c18d2",
  "#07934e",
  "#03f805",
  "#02c97a",
  "#07601d",
  "#05586b",
  "#0ed77b",
  "#093cf8",
  "#0a2066",
  "#099d81",
];
