import React from "react";
// navigate
import { useParams } from "react-router-dom";

// state and store
import { get_report, get_report_roles } from "../../../services/api/report";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import report_candidate from "../../../store/slices/report_candidate";

const Overview: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  // const report = useAppSelector(({ report }) => report.report);
  const roles = useAppSelector(({ report }) => report.report_candidates);
  const user = useAppSelector(({ auth }) => auth.user);

  const onLoad = React.useCallback(() => {
    if (id) {
      get_report(dispatch, id);
      get_report_roles(dispatch, id);
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="bg_report text-[white] font-abel">
      <div className="p-10 flex flex-col justify-between h-full">
        <div className="">
          {/* <p className="capitalize text-[30px] font-bold">{report?.title}</p> */}
        </div>
        <div className="bottom-0 pb-4">
          <div>
            <p className="capitalize md:text-[30px] text-[20px] font-bold">
              {user?.profile?.organization_name}
            </p>
            <p className="capitalize md:text-[30px] text-[20px] font-bold">
              Role:{" "}
              {roles.map(
                ({ order, id }, index) =>
                  `${order?.position?.name} ${
                    index + 1 === roles.length ? "" : "| "
                  }`
              )}
            </p>
            <p className="capitalize md:text-[50px] text-[25px] font-bold">Candidate Report</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
