import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import {
  IGetReportDispatch,
  IReport,
  IReportCandidate,
  IRolePooled,
  IReportStat,
  IReportState,
  IGenderState,
} from "../types/report";
import { IReportRoleCandidate } from "../types/report_candidate";

// Define a type for the slice state

// Define the initial state using that type
const initialState: IReportState = {
  current_page: "1",
  last_page: "1",
  reports: [],
  report: null,
  total: "",
  report_stat: null,
  report_candidates: [],
  role_pooled: [],
  gender_stats: [],
  loc_stat: [],
  loading: false,
  candidate: null,
  prev_current_page: '1',
  prev_last_page: '1',
  prev_reports: []
};

export const report = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<IGetReportDispatch>) => {
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
      state.reports = action.payload.data;
    },
    setPreviousReport: (state, action: PayloadAction<IGetReportDispatch>) => {
      state.prev_current_page = action.payload.current_page;
      state.prev_last_page = action.payload.last_page;
      state.prev_reports = action.payload.data;
    },
    setReport: (state, action: PayloadAction<IReport>) => {
      state.report = action.payload;
    },
    setReportStat: (state, action: PayloadAction<IReportStat>) => {
      state.report_stat = action.payload;
    },
    setReportCandidates: (state, action: PayloadAction<IReportCandidate[]>) => {
      state.report_candidates = action.payload;
    },
    setReportCandidate: (
      state,
      action: PayloadAction<IReportRoleCandidate>
    ) => {
      state.candidate = action.payload;
    },
    setReportRolePooled: (state, action: PayloadAction<IRolePooled[]>) => {
      state.role_pooled = action.payload;
    },
    setReportGenderStats: (state, action: PayloadAction<IGenderState[]>) => {
      state.gender_stats = action.payload;
    },
    setReportLocStats: (state, action: PayloadAction<IGenderState[]>) => {
      state.loc_stat = action.payload;
    },
    setTotalReport: (state, action: PayloadAction<string>) => {
      state.total = action.payload;
    },
    setNextPage: (state) => {
      state.current_page = state.current_page + 1;
    },
    setPrevPage: (state) => {
      state.current_page = (Number(state.current_page) - 1).toString();
    },

    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const {
  setNextPage,
  setPrevPage,
  setReports,
  setPreviousReport,
  setReportStat,
  setReport,
  setTotalReport,
  setReportLocStats,
  setReportCandidates,
  setReportRolePooled,
  setReportGenderStats,
  setReportCandidate,
  setLoading,
} = report.actions;

export default report.reducer;
