// icons
import icon_white from '../assets/images/icons/crest_white.png'
import comment from '../assets/images/icons/comment.svg'
import linkedin from '../assets/images/icons/linkedin.svg'
import drive from '../assets/images/icons/drive.svg'
import cv from '../assets/images/icons/cv.svg'
import play from '../assets/images/icons/play.svg'
import download from '../assets/images/icons/download.svg'
import user from '../assets/images/temporary/user.jpg'
import twitter from '../assets/images/icons/twitter.svg'
import employees from '../assets/images/icons/employees.svg'
import reports from '../assets/images/icons/reports.svg'
import invoices from '../assets/images/icons/invoices.svg'
import shared from '../assets/images/icons/shared.svg'

const db = {
  icon_black: "https://res.cloudinary.com/defwvlcup/image/upload/v1674636928/25thandstaffing/icon_black_yvmoug.png",
  icon_white: icon_white,
  comment: comment,
  linkedin: linkedin,
  drive: drive,
  cv: cv,
  play: play,
  download: download,
  user: user,
  twitter: twitter,
  employees: employees,
  reports: reports,
  invoices: invoices,
  shared: shared,

  applicants: [
    {
      id: 1, image: user, applicantName: 'Tinuke Adejini',
      title1: 'Comment', iconComment: comment,
      title2: 'View LinkedIn', iconLinkedIn: linkedin, linkLinkedin: 'https://25thandstaffing.com/',
      title3: 'View Drive', iconDrive: drive, linkDrive: 'https://25thandstaffing.com/',
      cvIcon: cv, cvLink: 'https://25thandstaffing.com/', VideoIcon: play, videoLink: 'https://25thandstaffing.com/',
      cvTitle: 'CV', videoTitle: 'Video',
      // applicantViewLink: '/',
    },
  ],

  stats: [
    {
      id: 1, icon: employees, title: '', amount: '10',
      // id: 2, icon: reports, title: '', amount: '10',
      // id: 3, icon: invoices, title: '', amount: '10',
      // id: 4, icon: shared, title: '', amount: '10',
    }
  ]
}

export default db;