import React from "react";

// router
import { Link, useNavigate } from "react-router-dom";

// constants
import avatar from "../../assets/images/temporary/user.png";

// state and types
import type { MenuProps } from "antd";
import { logout } from "../../services/api/auth";
import { get_full_profile } from "../../services/api/profile";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { get_notification } from "../../services/api/misc";

// components
import SearchInput from "../../components/ui/forms/SearchInput";

// constants and icons
import { BsBell } from "react-icons/bs";
import { setSearch } from "../../store/slices/navbar";
import { BiLogOut, BiSearchAlt } from "react-icons/bi";
import { Dropdown, Space, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(({ auth }) => auth.user);
  const loading = useAppSelector(({ auth }) => auth.loading);
  const search = useAppSelector(({ navbar }) => navbar.search);
  const notification = useAppSelector(({ navbar }) => navbar.notification);
  const s_visible = useAppSelector(({ navbar }) => navbar.search_visible);

  const onLoad = React.useCallback(() => {
    get_full_profile(dispatch);
    get_notification(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const user_avatar = user?.profile?.avatar || avatar;

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div>Hiring</div>,
    },
    {
      key: "2",
      label: <div>Not Hiring</div>,
    },
  ];

  return (
    <nav className="font-abel flex items-center justify-between px-5 py-2 border-b border-[#243677] bg-[#ffffff] text-[#243677]">
      <div className="w-[30%]">
        {s_visible && (
          <SearchInput
            name="search"
            value={search}
            placeholder="Search..."
            icon={<BiSearchAlt size={18} color="#243677" />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              dispatch(setSearch(value));
            }}
          />
        )}
      </div>
      <div className="items-center mx-2 flex cursor-pointer">
        <div className="relative items-center h-full justify-center mr-2">
          <BsBell
            size={25}
            color="#CDD8FE"
            onClick={() => navigate("/reports")}
          />
          {notification && (
            <div className="absolute -top-2 right-0 bg-[red] text-[10px] text-[white] px-[3px] rounded-full">
              {notification}
            </div>
          )}
        </div>

        <div className="mr-4 hidden md:block">
          <p className="capitalize text-[14px]">
            {user?.profile?.organization_name}
          </p>
        </div>
        <div className="rounded-full border-[#243677]">
          <Link to="/profile">
            <img
              src={user_avatar}
              alt="User Avatar"
              className="h-[40px] w-[40px] rounded-full"
            />
          </Link>
        </div>
        <Dropdown
          menu={{ items }}
          className="mx-4 hidden sm:hidden md:block lg:block"
        >
          <div
            className="bg-[#F4F7FF] rounded-[10px] px-3 py-2"
            onClick={(e) => e.preventDefault()}
          >
            <Space>
              Status: <span className="text-[#16D962]">Hiring</span>
              <DownOutlined />
            </Space>
          </div>
        </Dropdown>
        {loading ? (
          <Spin />
        ) : (
          <div
            className="flex items-center cursor-pointer"
            onClick={() => logout(dispatch, navigate)}
          >
            <BiLogOut size={20} color="#243677" />{" "}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
