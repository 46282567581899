import React from 'react'

// component
import { Spin } from 'antd';
import docIcon from '../../../assets/images/icons/doc.svg';

type ViewButtonProps = {
  title: string
  onSubmitHandler: () => void | object
  loading?: boolean
  icon?: React.ReactNode
  className?: string
  disabled?: boolean
}

const ViewButton: React.FC<ViewButtonProps> = ({ title, onSubmitHandler, loading, icon, className, disabled }) => {
  return (
    <button onClick={onSubmitHandler} disabled={loading || disabled} className={`text-[16px] font-abel py-3 px-10 bg-[#243677] text-[white] rounded-md hover:bg-[#CDD8FE] hover:text-[#243677] hover:border-[#CDD8FE] w-full border-[#243677] shadow-md flex items-center border font-bold ${className}`}>
      {loading && <Spin />} <span className='mr-3'><img src={docIcon} alt='icon' /></span> {title}
    </button>
  )
}

export default ViewButton