import React from 'react'

// router
import { useNavigate } from 'react-router-dom';

// types
import {CreateNewUser, ErrorCreateNewUser} from '../../../store/types/auth';

// state
import { useAppDispatch } from '../../../store/hooks';
import { userRegister } from '../../../services/api/auth';

const useRegister = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [user, userSet] = React.useState<CreateNewUser>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  const [userErr, userErrSet] = React.useState<ErrorCreateNewUser>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  const onChangeHandlerUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    userErrSet(prev => ({...prev, [name]: ''}))
    userSet(prev => ({...prev, [name]: value}))
  }

  const onValidateUser = (): boolean => {
    let error: boolean = true;
    if (!user.first_name) {
      userErrSet(prev => ({...prev, first_name: "warning"}))
      error = false
    }
    if (!user.last_name) {
      userErrSet(prev => ({...prev, last_name: "warning"}))
      error = false
    }
    if (!user.email) {
      userErrSet(prev => ({...prev, email: "warning"}))
      error = false
    }
    if (!user.password) {
      userErrSet(prev => ({...prev, password: "warning"}))
      error = false
    }
    if (!user.password || user.password_confirmation !== user.password) {
      userErrSet(prev => ({...prev, password_confirmation: "warning", password: 'warning'}))
      error = false
    }
    return error;
  }

  const onSubmitUser = async () => {
    if (onValidateUser()) {
      await userRegister(dispatch, user, navigate);
    }
  }

  return {user, userErr, onChangeHandlerUser, onSubmitUser}
}

export default useRegister