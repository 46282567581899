import React from "react";

// state and services
import { setNextPage, setPrevPage } from "../../../store/slices/report";
import { get_past_reports } from "../../../services/api/report";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { get_normal_date } from "../../../services/formatter/date";

//components
import Paginate from "../../../components/ui/paginate/Paginate";
import { LoadingReports } from "./LoadingReports";
import ReportTitle from "./ReportTitle";

// navigate
import { useNavigate } from "react-router-dom";

const ReportPrevious: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reports = useAppSelector(({ report }) => report.prev_reports);
  const current_page = useAppSelector(({ report }) => report.prev_current_page);
  const last_page = useAppSelector(({ report }) => report.prev_last_page);
  const loading = useAppSelector(({ report }) => report.loading);

  const onLoad = React.useCallback(() => {
    get_past_reports(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const generateRandomString = (): string => {
    return Math.floor(Math.random() * Date.now()).toString(36);
  };

  return (
    <>
      <div className="h-[100%] px-0 font-abel">
        {loading ? (
          <LoadingReports />
        ) : (
          reports?.map((item) => (
            <div
              key={item.id}
              className="flex items-center justify-between flex-wrap px-4 text-center border border-[#D0D0D0] rounded mb-4"
            >
              <div className="md:w-[15%] lg:w-[15%] w-full lg:border-r md:border-r border-[#D0D0D0] font-[700]  text-[15px] py-3 md:py-11 lg:py-11 h-full">
                {item?.title}
              </div>
              <div className="md:w-[70%] lg:w-[70%] w-[100%]">
                <div className="flex flex-wrap justify-center ">
                  {item?.report_role?.slice(0, 3).map((order) => (
                    <ReportTitle
                      key={item?.id + generateRandomString()}
                      slug={order?.order?.slug}
                      position={order?.order?.position?.name}
                    />
                  ))}
                </div>
                {/* <div>{item.title}</div> */}
                <div className="pt-3 text-[15px]">
                  {item.created_at ? get_normal_date(item.created_at) : ""}
                </div>
              </div>

              <div className="cursor-pointer md:w-[15%] lg:w-[15%] w-full lg:border-l md:border-l border-[#D0D0D0] py-4 pl-4">
                <div
                  onClick={() => navigate(`/report/${item.id}`)}
                  className="bg-[#243677] text-[#FFFFFF] hover:bg-[#CDD8FE] hover:text-[#243677] px-3 py-2 text-[15px] rounded-lg"
                >
                  View Report
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {reports.length ? (
        <div>
          <Paginate
            current_page={current_page}
            last_page={last_page}
            onNext={() => dispatch(setNextPage())}
            onPrev={() => dispatch(setPrevPage())}
          />
        </div>
      ) : null}
    </>
  );
};

export default ReportPrevious;
