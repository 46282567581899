import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// types
import { IJobStore, IJobGet, IJob } from "../types/jobs";

// Define the initial state using that type
const initialState: IJobStore = {
  jobs: [],
  job: null,
  current_page: 1,
  last_page: 1,
  total: "",
};

export const jobs = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setJobs: (state, action: PayloadAction<IJobGet>) => {
      state.jobs = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setJob: (state, action: PayloadAction<IJob>) => {
      state.job = action.payload;
    },
    setTotalJobs: (state, action: PayloadAction<string>) => {
      state.total = action.payload;
    },
  },
});

export const { setJobs, setJob, setTotalJobs } = jobs.actions;

export default jobs.reducer;
