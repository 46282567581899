import React from "react";

// routes
import { Route, Routes } from "react-router-dom";

// themes
import Theme from "../../themes";

// components
import ReportView from "../../screens/report/show";
import { Dashboard } from "../../screens/dashboard";
import { Applicants, Job, Jobs } from "../../screens/jobs";
import ReportDashboard from "../../screens/report/dashboard";
import ReportOverviewView from "../../screens/report/statistic";
import UpdateClient from "../../screens/profile/dashboard";
import Invoices from "../../screens/invoices/dashboard";
import Agreements from "../../screens/agreements/dashboard";
import SharedDocuments from "../../screens/shared/dashboard";

const AuthRoute: React.FC = () => {
  return (
    <Theme>
      <Routes>
        <Route path="/settings" element={<UpdateClient />} />

        {/* Applicants */}
        <Route path="/applicants/:id" element={<Applicants />} />

        {/* jobs */}
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/job/:id" element={<Job />} />

        {/* Report */}
        <Route path="/report/stats/:id" element={<ReportOverviewView />} />
        <Route path="/reports" element={<ReportDashboard />} />
        <Route path="/report/:id" element={<ReportView />} />

        {/* Invoices */}
        <Route path="/invoices" element={<Invoices />} />

        {/* Agreements  */}
        <Route path="/agreements" element={<Agreements />} />

        {/* Shared Documents  */}
        <Route path="/shared-documents" element={<SharedDocuments />} />

        {/* Home */}
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </Theme>
  );
};

export default AuthRoute;
