import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import {
  openNotification,
  errorNotification,
} from "../../components/ui/alerts";

// types and state
import { setJobs, setJob, setTotalJobs } from "../../store/slices/jobs";
import { setLoading, setUpdate } from "../../store/slices/loading";

type IGetJobs = (dispatch: any) => Promise<void>;
export const get_jobs: IGetJobs = async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-jobs");
    dispatch(setJobs(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

type IGetJob = (dispatch: any, id: string) => Promise<void>;
export const get_job: IGetJob = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-job/${id}`);

    dispatch(setJob(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

type IApplyJob = (dispatch: any, slug: string) => Promise<void>;
export const apply: IApplyJob = async (dispatch, slug) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.get(`/apply/${slug}`);

    openNotification({ status: "success", message: response.data?.message });
  } catch (error) {
    if (isAxiosError(error)) {
      errorNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    } else {
      errorNotification({
        status: "error",
        message: "Please check your Internet.",
      });
    }
  }
  dispatch(setUpdate());
};

export const get_total_job = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-total-order");

    dispatch(setTotalJobs(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};
