import { useAppSelector } from "../../../store/hooks";

const Results = () => {
  const roles = useAppSelector(({ report }) => report.role_pooled);
  const report_stat = useAppSelector(({ report }) => report.report_stat);

  return (
    <div className="font-abel px-5">
      <p className="capitalize text-[22px] mb-2 font-bold text-[#243677]">
        Sourcing
      </p>
      {report_stat?.sourcing && (
        <div
          className="text-[16px] w-full"
          dangerouslySetInnerHTML={{ __html: report_stat?.sourcing }}
        />
      )}
      <p className="capitalize text-[22px] mb-2 mt-4 font-bold text-[#243677]">
        Results
      </p>
      {report_stat?.results && (
        <div
          className="text-[16px] text-[#4E4E4E] mb-5"
          dangerouslySetInnerHTML={{
            __html: report_stat?.results,
          }}
        />
      )}
      <p className="text-[16px] text-[#4E4E4E] mb-5">
        The numbers can be seen below:
      </p>
      <table className="text-[14px] text-[#4E4E4E] mb-5 font-abel">
        <tr>
          <th>S/N</th>
          <th>ROLES</th>
          <th>CANDIDATES POOLED</th>
          <th>CANDIDATES CONTACTED</th>
          <th>CANDIDATES SHORTLISTED</th>
        </tr>

        {roles.map((role, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td className="capitalize">{role?.order?.name}</td>
            <td>{role.applicants}</td>
            <td>{role.applicants}</td>
            <td>{role.candidates}</td>
          </tr>
        ))}
      </table>

      {report_stat?.results ? (
        <><p className="text-[16px] text-[#4E4E4E] mb-2">
            Candidates were disqualified due to the following reasons:
          </p>
        <div
          className="text-[16px] text-[#4E4E4E] mb-5"
          dangerouslySetInnerHTML={{
            __html: report_stat?.results,
          }}
        /></>
      ) : (
        <>
          <p className="text-[16px] text-[#4E4E4E] mb-2">
            Candidates were disqualified due to the following reasons:
          </p>
          <ol className="bullet-point2 text-[16px] text-[#4E4E4E]">
            <li className="mb-2">
              Candidates’ profiles do not match the job requirements{" "}
            </li>
            <li className="mb-2">
              Candidates’ quality of relevant professional experience
            </li>
            <li className="mb-2">Industry experience </li>
            <li className="mb-2">Wrong skillset.</li>
          </ol>
        </>
      )}
    </div>
  );
};

export default Results;
