import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import ReportAvailable from "../misc/ReportAvailable";
import ReportPrevious from "../misc/ReportPrevious";

import { Link } from "react-router-dom";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Available Report",
    children: <ReportAvailable />,
  },
  {
    key: "2",
    label: "Previous Reports",
    children: <ReportPrevious />,
  },
];

const ReportDashboard: React.FC = () => {
  return (
    <div className="w-full h-[85%] pl-8 font-abel">
      <div className="flex justify-end px-5 pt-5">
        <Link
          to="https://25thandstaffing.com/categories"
          // onClick={() => navigate(`/jobs}`)}
          className="bg-[#243677] text-[#FFFFFF] hover:bg-[#CDD8FE] hover:text-[#243677] text-[15px] px-4 py-2 rounded-lg"
        >
          Request Job Order
        </Link>
      </div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default ReportDashboard;
