import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// types
import { IApplicant, IApplicantData } from '../types/applicant'

// Define a type for the slice state


// Define the initial state using that type
const initialState: IApplicant = {
	current_page: 1,
	last_page: 1,
  applicants: []
};

export const applicant = createSlice({
	name: 'applicant',
	initialState,
	reducers: {
		setApplicants: (state, action: PayloadAction<IApplicantData>) => {
      state.current_page = action.payload.current_page
      state.last_page = action.payload.last_page
      state.applicants = action.payload.data
		},
    setNextPage: (state) => {
      if (state.current_page < state.last_page) {
        state.current_page = state.current_page + 1
      }
    },
    setPrevPage: (state) => {
      if (!(state.current_page <= 1)) {
        state.current_page = state.current_page - 1
      }
    }
	}
});

export const { setNextPage, setPrevPage, setApplicants } = applicant.actions;

export default applicant.reducer;
