import React from 'react'

// components
import { Modal } from 'antd';
import OTPInput from '../../../components/elements/forms/OTPInput';

type ICompleteProfile = {
  visible: boolean
  onCancel: (res: string | null) => void
}

const ChangePasswordToken: React.FC<ICompleteProfile> = ({ visible, onCancel }) => {
  const [data, dataSet] = React.useState('');

  const onLoad = React.useCallback(() => {
    if (data.length === 6) {
      onCancel?.(data)
      dataSet('')
    }
  }, [data, onCancel])

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <Modal centered open={visible} wrapClassName="bg-[#010925] bg-opacity-80" bodyStyle={{backgroundColor: 'transparent'}} onCancel={() => onCancel?.(null)} footer={null} closable={false}>
      <div className='flex flex-col justify-center items-center'>
        <p className='text-md text-left text-[#aaaaaa]'>An Otp has been sent to your email {localStorage.getItem('@email')}.</p>
        <OTPInput value={data} handleChange={res => dataSet(res)} />
      </div>
    </Modal>
  )
}

export default ChangePasswordToken