// notification
import { notification } from 'antd';

export type INotification = {
    status: 'success' | 'error'
    message: string
}

export const openNotification = ({status, message}: INotification) => {
    notification[status]({
        message: status.toUpperCase(),
        description: message,
        placement: 'topRight',
    })
}

export const errorNotification = ({status, message}: INotification) => {
    notification.error({
        message: status.toUpperCase(),
        description: message,
        placement: 'topRight',
    })
}