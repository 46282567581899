import React from "react";

// types and services
import { useAppSelector } from "../../../store/hooks";
import db from "../../../constants/database";

// constants
// import { FaArrowUp } from "react-icons/fa";

const StatsClients: React.FC = () => {
  const total_jobs = useAppSelector(({ jobs }) => jobs?.total);
  const total_report = useAppSelector(({ report }) => report.total);

  return (
    <div className="font-abel flex flex-wrap justify-between mt-2 mb-5">
      <div className="text-left bg-[white] border border-[#D5D8DA] pt-2 pb-4 mb-2 md:w-[24%] lg:w-[24%] w-[98%] md:mr-3">
        <div className="flex border-b border-[rgb(213,216,218)] mb-2 pb-2 pl-4">
          <img src={db.employees} alt="icon" />
          <p className="md:text-[20px] text-[16px] ml-2">
            Total Employees
          </p>
        </div>
        <p className="md:text-[40px] md:leading-10 leading-6 text-[30px] mb-3 pl-4">
          10
        </p>
      </div>
      <div className="text-left bg-[white] border border-[#D5D8DA] pt-2 pb-4 mb-2 md:w-[24%] lg:w-[24%] w-[98%] md:mr-3">
        <div className="flex border-b border-[rgb(213,216,218)] mb-2 pb-2 pl-4">
          <img src={db.reports} alt="icon" />
          <p className="md:text-[20px] text-[16px] ml-2">
            Total Reports
          </p>
        </div>
        <p className="md:text-[40px] md:leading-10 leading-6 text-[30px] mb-3 pl-4">
          {total_report}
        </p>
        {/* <div className="text-[green] flex items-center justify-end">
          <FaArrowUp size={15} color="green" />
          <p className="text-[20px]">+20%</p>
        </div> */}
      </div>
      <div className="text-left bg-[white] border border-[#D5D8DA] pt-2 pb-4 mb-2 md:w-[24%] lg:w-[24%] w-[98%] md:mr-3">
        <div className="flex border-b border-[rgb(213,216,218)] mb-2 pb-2 pl-4">
          <img src={db.shared} alt="icon" />
          <p className="md:text-[20px] text-[16px] ml-2">
            Total Jobs/Orders
          </p>
        </div>
        <p className="md:text-[40px] md:leading-10 leading-6 text-[30px] mb-3 pl-4">
          {total_jobs}
        </p>
        {/* <div className="text-[green] flex items-center justify-end">
          <FaArrowUp size={15} color="green" />
          <p className="text-[20px]">+20%</p>
        </div> */}
      </div>
      <div className="text-left bg-[white] border border-[#D5D8DA] pt-2 pb-4 mb-2 md:w-[24%] lg:w-[24%] w-[98%]">
        <div className="flex border-b border-[rgb(213,216,218)] mb-2 pb-2 pl-4">
          <img src={db.invoices} alt="icon" />
          <p className="md:text-[20px] text-[16px] ml-2">
            Total Invoices
          </p>
        </div>
        <p className="md:text-[40px] md:leading-10 leading-6 text-[30px] mb-3 pl-4">
          10
        </p>
      </div>
    </div>
  );
};

export default StatsClients;
