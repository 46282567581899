import { useAppSelector } from "../../../store/hooks";

const Limitations = () => {
  const report_stat = useAppSelector(({ report }) => report.report_stat);

  return (
    <div className="font-abel px-5">
      <p className='capitalize text-[22px] mb-3 font-bold text-[#243677]'>Limitations</p>
      {report_stat?.limitations && (
        <div
          className="text-[16px] w-full"
          dangerouslySetInnerHTML={{ __html: report_stat?.limitations }}
        />
      )}

      <p className='capitalize text-[22px] mb-3 mt-4 font-bold text-[#243677]'>Comments</p>
      {report_stat?.comment && (
        <div
          className="text-[16px] w-full"
          dangerouslySetInnerHTML={{ __html: report_stat?.comment }}
        />
      )}
    </div>
  );
};

export default Limitations;
