import React from 'react'

// components
import TextInput from '../../../components/elements/forms/TextInput'

// theme
import Landing from '../app'

// assets
import SubmitButton from '../../../components/elements/forms/SubmitButton';
import { Link, useNavigate } from 'react-router-dom';

// types and state
import { forgot_password } from '../../../services/api/auth';
import { ErrorWarning } from '../../../store/types/misc';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loading = useAppSelector(({ loading }) => loading?.loading);

  const [email, emailSet] = React.useState<string>('');
  const [err, errSet] = React.useState<ErrorWarning>('');

  const onSubmitHandler = async () => {
    const response = await forgot_password(dispatch, { email });
    if (response) {
      navigate('/change-password');
    }
  };

  return (
    <Landing>
      <div className="md:flex font-abel md:py-10 md:flex-row bg-[#EFF2FE] md:justify-center md:items-center">
        <div className='bg-[white] px-4 py-10 items-center md:mx-5 max-w-[695px] border-none rounded-lg md:px-12 md:pt-12 md:pb-16 md:my-12'>
          <div className='text-left w-full my-5'>
            <h3 className='md:text-[30px] text-[23px] font-[900]'>Welcome back to 25th &amp; Staffing</h3>
            <span className='text-lg font-[700] text-[#aaaaaa]'>Sign In to continue</span>
          </div>

          <TextInput placeholder='Email address' status={err} name="email" value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { emailSet(e.target.value); errSet('') }} />

          <div className='flex items-center mt-7 align-center'>
            <div className='w-[100%]'>
              <SubmitButton loading={loading} title='RESET PASSWORD' onSubmitHandler={onSubmitHandler} className="bg-[#243677] font-[400] text-white md:hover:bg-[white] md:hover:text-[#243677] w-[30%]" />
            </div>
          </div>

          <div className='text-center mt-6'>
            <span>Don&apos;t have an account? <Link to="/login" className='text-[#243677]'> Sign In</Link></span>
          </div>

        </div>
      </div>
    </Landing>
  )
}

export default ForgotPassword