import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface IAuthState {
  loading: boolean;
  update: boolean;
  more: boolean;
  delete: boolean;
}

// Define the initial state using that type
const initialState: IAuthState = {
  loading: false,
  update: false,
  more: false,
  delete: false,
};

export const loading = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setUpdate: (state) => {
      state.update = !state.update;
    },
    setDelete: (state) => {
      state.delete = !state.delete;
    },
    setMore: (state) => {
      state.more = !state.more;
    },
  },
});

export const { setLoading, setUpdate, setDelete, setMore } = loading.actions;

export default loading.reducer;
