//
import React from "react";

//constants
import { useNavigate } from "react-router-dom";
import { gen_color } from "../../../constants/colors";

type IReportTitle = {
  slug: string;
  position: string;
};

const ReportTitle: React.FC<IReportTitle> = ({ position, slug }) => {
  const navigate = useNavigate();
  const bg_color = gen_color[Math.floor(Math.random() * gen_color.length)];

  return (
    <span
      onClick={() => navigate(`/job/${slug}`)}
      style={{ backgroundColor: bg_color }}
      className={`text-[white] font-bold text-[15px] capitalize cursor-pointer border border-[#D0D0D0] rounded-xl text-center py-1 px-4 m-1`}
    >
      {position}
    </span>
  );
};

export default ReportTitle;
