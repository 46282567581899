import React from 'react'

// components
import Header from './header'
import Footer from './footer';

type LandingTheme = {
  className?: string
  children?: React.ReactNode,
}

const Landing: React.FC<LandingTheme> = ({children, className}) => {
  return (
    <div className='w-full font-abel'>
      <Header />

      <section className={`w-full justify-center ${className}`}>
        {children}
      </section>
      <Footer />
    </div>
  )
}

export default Landing