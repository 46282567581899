import React from "react";

// constants
import pay from "../../../assets/images/icons/pay.svg";
import calendar from "../../../assets/images/icons/calendar.svg";
import star from "../../../assets/images/icons/star.svg";
import location from "../../../assets/images/icons/location.svg";
import avatar_user from "../../../assets/images/temporary/user.png";

// components
import { Modal, Spin } from "antd";
import SubmitButton from "../../../components/elements/forms/SubmitButton";
import RichTextInputBox from "../../../components/elements/forms/RichTextInputBox";

// router
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import {
  client_comment_candidates,
  get_role_candidates,
} from "../../../services/api/report";
import { ICandidateComment } from "../../../store/types/report";

type IApplicant = {
  id: string;
};

const Applicant: React.FC<IApplicant> = ({ id }) => {
  const dispatch = useAppDispatch();
  const candidate = useAppSelector(({ report }) => report.candidate);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const loading = useAppSelector(({ loading }) => loading.update);
  const [comment, commentSet] = React.useState<ICandidateComment>({
    id: "",
    name: "",
  });

  const onLoad = React.useCallback(() => {
    if (id) {
      get_role_candidates(dispatch, id);
      commentSet((prev) => ({ ...prev, id }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="font-abel w-full text-left text-[#4E4E4E] bg-[white]">
      <div className="w-full bg-[#e0e7ff] h-full my-6 p-6">
        <div className=" flex flex-wrap justify-center items-center w-full h-full">
          <div className="rounded-full border-[#E9EAEF] border-[6px] w-[152px] h-[152px] overflow-hidden my-3 md:mr-5">
            {loading ? (
              <div className="flex h-full items-center justify-center">
                <Spin size="large" />
              </div>
            ) : (
              <img
                src={
                  candidate?.user?.profile && candidate?.user?.profile?.avatar
                    ? candidate?.user?.profile?.avatar
                    : avatar_user
                }
                alt="User"
                className="h-[100%] w-full rounded-full object-cover"
              />
            )}
          </div>
          <div className="text-[#243677] max-w-[1100px] ">
            <div className="flex justify-between items-center mb-2">
              <div>
                <h1 className="md:text-3xl text-[20px] font-light">
                  About <span className="font-bold">{candidate?.user?.profile?.first_name} </span> 
                  <span>{candidate && candidate?.noun ? '| ' : ''}</span> 
                      {candidate?.noun}
                </h1>
                <p className="capitalize">
                  {candidate?.user?.profile?.address?.about?.profession?.name}
                </p>
              </div>
              <div className="w-[150px] mr-2">
                <SubmitButton
                  title="Add Comment"
                  onSubmitHandler={() => setIsModalOpen(true)}
                />
              </div>
            </div>

            <Modal open={isModalOpen} footer={[]}>
              <div className="my-5">
                <RichTextInputBox
                  placeholder="Comment on Candidate"
                  value={comment.name}
                  onChange={(e) => commentSet((prev) => ({ ...prev, name: e }))}
                />
              </div>
              <div className="my-5">
                <SubmitButton
                  title="Submit"
                  onSubmitHandler={() => {
                    if (comment.name && comment.id) {
                      client_comment_candidates(dispatch, comment);
                      handleCancel();
                    }
                  }}
                />
              </div>
            </Modal>
            
            <p className="text-lg mb-4 max-w-[570px]">
              {candidate?.summary}
              {/* {candidate?.user?.profile?.address?.about?.about_me} */}
            </p>
            <div className="flex justify-between flex-wrap w-full">
              <div className="bg-[white] h-[54px] w-full sm:w-full md:w-[23%] lg:w-[23%] px-2 mr-2 mb-2 flex items-center">
                <img src={pay} alt="icon" className="mr-1" />
                <p className="text-sm capitalize">
                  Expected Pay - {candidate?.expected_pay}
                </p>
              </div>
              <div className="bg-[white] h-[54px] w-full sm:w-full md:w-[23%] lg:w-[23%] px-2 mr-2 mb-2 flex items-center">
                <img src={calendar} alt="icon" className="mr-1" />
                <p className="text-sm capitalize">
                  Notice Period - {candidate?.notice_period}
                </p>
              </div>
              <div className="bg-[white] h-[54px] w-full sm:w-full md:w-[23%] lg:w-[23%] px-2 mr-2 mb-2 flex items-center">
                <img src={star} alt="icon" className="mr-1" />
                <p className="text-sm capitalize">
                  Status - <span className="text-[green]">Ready to work</span>
                </p>
              </div>
              <div className="bg-[white] h-[54px] w-full sm:w-full md:w-[23%] lg:w-[23%] px-2 mr-2 mb-2 flex items-center">
                <img src={location} alt="icon" className="mr-1" />
                <p className="text-sm capitalize">
                  Location -{" "}
                  {candidate?.user?.profile?.address?.residential_state?.name}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1150px] m-auto mt-5 mb-10">
        <div className="flex justify-between flex-wrap w-full h-full text-base">
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">First Name</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4">
                {candidate?.user?.profile?.first_name}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Last Name</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4">
                {candidate?.user?.profile?.last_name}
              </p>
            </div>
          </div>
          {/* <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Other Name</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4"></p>
            </div>
          </div> */}
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Email Address</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4">{candidate?.user?.email}</p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Phone Number</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4">
                {candidate?.user?.profile?.phone}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Gender</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4 capitalize">
                {candidate?.user?.profile?.gender_status?.name}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Date of Birth</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4">
                {candidate?.user?.profile?.dob}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Residential</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4 capitalize">
                {candidate?.user?.profile?.address?.address}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Country</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4 capitalize">
                {candidate?.user?.profile?.address?.nationality?.name}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">State of Residence</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4 capitalize">
                {candidate?.user?.profile?.address?.residential_state?.name}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">State of Origin</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4 capitalize">
                {candidate?.user?.profile?.address?.origin_state?.name}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Religion</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4 capitalize">
                {candidate?.user?.profile?.address?.about?.religion?.name}
              </p>
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Marital Status</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4 capitalize">
                {candidate?.user?.profile?.address?.about?.marital_status?.name}
              </p>
            </div>
          </div>
          {/* <div className="mb-4 md:px-3 w-full md:max-w-[330px]">
            <label className="text-[#A4A4A4]">Languages</label>
            <div className="h-[55px] bg-[#F4F6FD] w-full flex items-center">
              <p className="text-[#707070] px-4">English</p>
            </div>
          </div> */}
          

          {/* Report Data */}
          {/* <div className="mb-4 md:px-3 w-full">
            <label className="text-[#243677] font-[700]">
              Noun <span className="text-[#A4A4A4] font-[400]">(Optional)</span>
            </label>
            <div className="h-[95px] bg-[#F4F6FD] w-full flex pt-3  text-[#707070] px-4">
              {candidate?.noun && (
                <div
                  className="w-full flex pt-3  text-[#707070]"
                  dangerouslySetInnerHTML={{
                    __html: candidate?.noun,
                  }}
                />
              )}
            </div>
          </div> */}
          <div className="mb-4 md:px-3 w-full">
            <label className="text-[#A4A4A4]">
              Professional Summary
            </label>
            <div className="min-h-[95px] bg-[#F4F6FD] w-full flex pt-3  text-[#707070] px-4">
              {candidate?.professional_summary && (
                <div
                  className="w-full flex pt-3 text-[#707070]"
                  dangerouslySetInnerHTML={{
                    __html: candidate?.professional_summary,
                  }}
                />
              )}
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full">
            <label className="text-[#A4A4A4]">Pros</label>
            <div className="min-h-[95px] bg-[#F4F6FD] w-full flex pt-3  text-[#707070] px-4">
              {candidate?.pros && (
                <div
                  className="w-full flex pt-3 text-[#707070]"
                  dangerouslySetInnerHTML={{ __html: candidate?.pros }}
                />
              )}
            </div>
          </div>
          <div className="mb-4 md:px-3 w-full">
            <label className="text-[#A4A4A4]">Cons</label>
            <div className="min-h-[95px] bg-[#F4F6FD] w-full flex pt-3  text-[#707070] px-4">
              {candidate?.cons && (
                <div
                  className="w-full flex pt-3 text-[#707070]"
                  dangerouslySetInnerHTML={{ __html: candidate?.cons }}
                />
              )}
            </div>
          </div>
          {/* <div className="mb-4 md:px-3 w-full">
            <label className="text-[#A4A4A4]">Comments</label>
            <div className="h-[95px] bg-[#F4F6FD] w-full flex pt-3  text-[#707070] px-4">
              {candidate?.comment && (
                <div
                  className="w-full flex pt-3 text-[#707070]"
                  dangerouslySetInnerHTML={{ __html: candidate?.comment }}
                />
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Applicant;
