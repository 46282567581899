import { useNavigate } from "react-router-dom";
import SubmitButton from "../../../components/elements/forms/SubmitButton"


const Invoices = () => {
    const navigate = useNavigate();

    return (
        <div className="w-full h-[85%] pl-8 font-abel">
            <div className="flex justify-between px-5 pt-5">
                <p className="text-[24px]">Invoices</p>
                <div></div>
            </div>
            <div className="flex justify-center items-center px-3 py-3 mt-5 font-[700] text-[#1E1E1E] border-b">
                <div className="mx-1 border-r px-2 w-1/12 text-center font-bold">
                    S/N
                </div>
                <div className="mx-1 border-r px-2 w-4/12 text-center font-bold">
                    Invoice Title
                </div>
                <div className="mx-1 border-r px-2 w-2/12 text-center font-bold">
                    Date Issued
                </div>
                <div className="mx-1 border-r px-2 w-2/12 text-center font-bold">
                    Status
                </div>
                <div className="mx-1 px-2 w-3/12 text-center font-bold">View Details</div>
            </div>
            <div className="w-full">
                <div className='flex justify-center items-center px-3 py-2 mb-3 border-b border-[#BCBCBC] font-abel'>
                    <div className="mx-1 border-r px-2 w-1/12 text-center cursor-pointer flex justify-center ">
                        <p className='text-[#1E1E1E] text-[15px] md:block block'>1</p>
                    </div>
                    <div className="mx-1 border-r px-2 w-4/12 text-center cursor-pointer">
                        <h3 className='text-[15px] font-[400] text-[#100F0F] capitalize'>Software Development</h3>
                    </div>
                    <div className="mx-1 border-r px-2 w-2/12 text-center cursor-pointer">
                        <p className='text-[#1E1E1E] text-[15px] md:block block'>02/10/2023</p>
                    </div>
                    <div className="mx-1 border-r px-2 w-2/12 text-center cursor-pointer">
                        <div className="w-[123px] h-[44px] bg-[#EAFCE5] border border-[#5CF530] flex items-center justify-center m-auto">
                            <p className='text-[#1E1E1E] text-[15px] md:block block'>Paid</p>
                        </div>
                    </div>
                    <div className="mx-1 flex px-2 w-3/12 justify-center cursor-pointer">
                        <SubmitButton title='View Details' onSubmitHandler={() => navigate(`/job/`)} className="mr-1 w-[120px]" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoices