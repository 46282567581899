import React from 'react'

type ISocialButton = {
    icon: string
    title: string
    onPress: () => void
}

const SocialButton: React.FC<ISocialButton> = ({ title, icon, onPress }) => {
    return (
        <div onClick={onPress} className='text-center mx-2'>
            <img src={icon} alt={title} className='h-[31px] w-[31px] m-auto' />
            <div className='text-[12px] text-[#243677] text-center underline mt-2'>{title}</div>
        </div>
    )
}

export default SocialButton