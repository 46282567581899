import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDataType } from '../types/misc';

// Define a type for the slice state
interface IAuthState {
	roles: IDataType[] 
	countries: IDataType[],
  states: IDataType[],
  genders: IDataType[],
  religions: IDataType[],
  marital_statuses: IDataType[],
}

// Define the initial state using that type
const initialState: IAuthState = {
  roles: [],
  countries: [],
  states: [],
  genders: [],
  religions: [],
  marital_statuses: []
};

export const misc = createSlice({
	name: 'misc',
	initialState,
	reducers: {
		setRoles: (state, action: PayloadAction<IDataType[]>) => {
			state.roles = action.payload;
		},
		setCountries: (state, action: PayloadAction<IDataType[]>) => {
			state.countries = action.payload
		},
    setStates: (state, action: PayloadAction<IDataType[]>) => {
			state.states = action.payload
		},
    setGenders: (state, action: PayloadAction<IDataType[]>) => {
      state.genders = action.payload
    },
    setReligions: (state, action: PayloadAction<IDataType[]>) => {
      state.religions = action.payload
    },
    setMaritalStatus: (state, action: PayloadAction<IDataType[]>) => {
      state.marital_statuses = action.payload
    }
	}
});

export const { setRoles, setCountries, setStates, setGenders, setReligions, setMaritalStatus } = misc.actions;

export default misc.reducer;
