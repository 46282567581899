import React from 'react'

// components
import SubmitButton from '../../elements/forms/SubmitButton';

// types
import { JobOrderType } from '../../../store/types/jobs'
// import db from '../../../constants/database';

// navigate
import { useNavigate } from 'react-router-dom';

// constants
import { get_month_and_day } from '../../../services/formatter/date';

type IJobCardType = {
  // key: string,
  index: number,
  item: JobOrderType,
}
// type IJob = {
//   key: string,
//   item: IJob
// }

const JobCard: React.FC<IJobCardType> = ({ item, index }) => {
  const navigate = useNavigate();
  // const avatar = item?.user?.profile?.avatar || db.icon_black;
  const date = get_month_and_day(item?.created_at)

  return (
    <div className='flex justify-center items-center px-3 py-2 mb-3 border-b border-[#BCBCBC] font-abel'>
      <div className="mx-1 border-r px-2 w-1/12 text-center cursor-pointer flex justify-center ">
        <p className='text-[#243677] text-[15px] md:block block'>{index + 1}</p>
        {/* <div className='rounded-full md:h-[50px] md:w-[50px] md:flex md:items-center md:justify-center'>
            <img src={avatar} alt="Company Avatar" className='border md:border-2 w-[40px] rounded-full md:w-[50px] object-fill' />
          </div> */}
      </div>
      <div className="mx-1 border-r px-2 w-5/12 text-center cursor-pointer">
        <h3 className='text-[15px] font-[700] text-[#100F0F] capitalize'>{item?.position?.name}</h3>
      </div>
      {/* <div className="mx-1 border-r px-2 w-3/12 text-center cursor-pointer flex">
          <span className='text-[15px] mx-[2px] md:mr-4 text-[#4E4E4E] capitalize block'>{item?.state?.name}, {item?.state?.country?.name} - {item?.job_type?.name?.toUpperCase()}
          </span>
        </div> */}
      <div className="mx-1 border-r px-2 w-3/12 text-center cursor-pointer">
        <p className='text-[#243677] text-[15px] md:block block'>{date}</p>
      </div>
      <div className="mx-1 flex px-2 w-3/12 text-center cursor-pointer">
        <SubmitButton title='View JD' onSubmitHandler={() => navigate(`/job/${item.slug}`)} className="mr-1 w-[140px]" />
        {/* <SubmitButton title='View Applicants' onSubmitHandler={() =>  navigate(`/applicants/${item.slug}`)} /> */}
      </div>
    </div>
  )
}

export default JobCard