import React, { useState } from "react";

// component
import { Drawer } from "antd";
import SocialLink from "../../../components/ui/links/SocialLink";
import SocialButton from "../../../components/ui/links/SocialButton";
import Applicant from "../../jobs/applicant";
import { LoadingReportCandidates } from "./LoadingReports";

// constants
import linkedin from "../../../assets/images/icons/linkedin.svg";
import link from "../../../assets/images/icons/link.svg";
import drive from "../../../assets/images/icons/drive.svg";
import avatar_user from "../../../assets/images/temporary/user.png";

// state
import { get_reports_order_candidates } from "../../../services/api/report_candidate";
import { IReportRoleCandidate } from "../../../store/types/report_candidate";
import SubmitButton2 from "../../../components/elements/forms/SubmitButton2";

type IUserData = {
  id: string;
};

const UserData: React.FC<IUserData> = ({ id }) => {
  const [user_id, userIDSet] = React.useState<string>("");
  const [data, dataSet] = React.useState<IReportRoleCandidate[]>([]);
  const [loading, loadingSet] = React.useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const onLoad = React.useCallback(async () => {
    if (id) {
      loadingSet(true);
      const response = await get_reports_order_candidates(id);
      dataSet(response);
      loadingSet(false);
    }
  }, [id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      {loading ? (
        <LoadingReportCandidates />
      ) : (
        data.map((item) => (
          <div
            key={item?.id}
            className="bg-[#F6F7FB] sm:w-[90%] w-[90%] md:w-[30%] lg:w-[30%] rounded-lg pb-6 px-8 text-center mt-14 mb-6 mx-3"
          >
            <div className="max-w-[230px] m-auto">
              <div className="rounded-full border-[#E9EAEF] border-[6px] w-[122px] h-[122px] text-center object-cover overflow-hidden m-auto mb-3 -mt-14">
                <img
                  src={
                    item?.user?.profile && item?.user?.profile?.avatar
                      ? item?.user?.profile?.avatar
                      : avatar_user
                  }
                  alt="User"
                  className="h-[100%] w-full rounded-full object-cover"
                />
              </div>
              <h2 className="text-2xl text-[#243677]">
                {item?.user?.profile?.first_name}{" "}
                {item?.user?.profile?.last_name}
              </h2>
              <div className="flex justify-center mt-4 mb-7">
                {item?.user?.profile?.address?.about?.social?.linked_in ? (
                  <SocialLink
                    link={
                      item?.user?.profile?.address?.about?.social?.linked_in
                    }
                    title="View LinkedIn"
                    icon={linkedin}
                  />
                ) : (
                  <SocialButton
                    onPress={() => null}
                    title="LinkedIn"
                    icon={linkedin}
                  />
                )}
                {item?.report ? (
                  <SocialLink
                    link={item.report}
                    title="View Report"
                    icon={drive}
                  />
                ) : (
                  <SocialButton
                    onPress={() => null}
                    title="View Link"
                    icon={link}
                  />
                )}
              </div>
              <SubmitButton2
                title="View more details"
                onSubmitHandler={() => {
                  userIDSet(item?.id);
                  setOpen(true);
                }}
                className="mb-3"
              />
            </div>

            <Drawer
              placement="right"
              onClose={() => setOpen(false)}
              open={open}
              width="85%"
            >
              <Applicant id={user_id} />
            </Drawer>
            {/* <CommentApplicant
        visible={show}
        onHandleOk={() => showSet(false)}
        onCancel={() => showSet(false)}
      /> */}
          </div>
        ))
      )}
    </>
  );
};

export default UserData;
