import React from "react";

// constants
import ReportJob from "../misc/ReportJob";
import { ReportTitle } from "../misc/LoadingReports";
import ViewButton from "../../../components/elements/forms/ViewButton";

// navigate
import { useNavigate, useParams } from "react-router-dom";

// state and store
import { get_report, update_report_seen } from "../../../services/api/report";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

const ReportView: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const report = useAppSelector(({ report }) => report.report);
  const loading = useAppSelector(({ report }) => report.loading);

  const onLoad = React.useCallback(() => {
    if (id) {
      get_report(dispatch, id);
      update_report_seen(id);
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <div className="font-abel w-full text-center text-[#4E4E4E] bg-[white]">
        {loading ? (
          <ReportTitle />
        ) : (
          <div className="text-[#243677] border-b border-[#DADADA] text-2xl md:text-3xl py-3 capitalize">
            {report?.title}
          </div>
        )}

        <div className="w-full flex items-center justify-center py-2 px-3 my-3">
          <div>
            <ViewButton
              title="View Report"
              onSubmitHandler={() => {
                if (id) navigate(`/report/stats/${id}`);
              }}
            />
          </div>
        </div>

        {id && <ReportJob id={id} />}
      </div>
    </>
  );
};

export default ReportView;
