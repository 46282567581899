import React from 'react'

// components
import Button from '../../../../../components/ui/links/Button'

// router
import { Link } from 'react-router-dom'

// constants
import icon from '../../../../../assets/images/icons/crest.png'
import { IoMenuOutline, IoCloseSharp } from "react-icons/io5";
// import { AiOutlineUser } from "react-icons/ai";
// import { FaRocketchat } from "react-icons/fa";

const LINKS: {
  name: string
  link: string
}[] = [
    { name: 'Origin', link: 'https://www.25thandstaffing.com/origin' },
    { name: 'Services', link: 'https://www.25thandstaffing.com/services' },
    { name: 'Team', link: 'https://www.25thandstaffing.com/teams' },
    { name: 'Job Marketplace', link: 'https://www.25thandstaffing.com/marketplace' },
    { name: 'Talk to Us', link: 'https://www.25thandstaffing.com/talk-to-us' },
  ]

const Navbar: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <div className='w-full font-abel bg-[#FFFFFF] border-b md:border-b border-[#243677]'>
      <div className='flex w-full items-center py-4 md:px-10 px-7'>
        <div className='flex w-full md:w-4/12 items-center justify-between'>
          <div>
            <Link to="https://www.25thandstaffing.com">
              <img src={icon} className="w-20 cursor-pointer" alt="icon" />
            </Link>
          </div>
          <div onClick={() => setOpen(!open)} className='text-3xl md:hidden cursor-pointer'>
            {open ? <IoCloseSharp /> : <IoMenuOutline />}
          </div>
        </div>

        <ul className={`md:flex md:bg-[none] bg-[white]  md:border-none md:justify-end text-left md:w-full md:items-center md:static md:pb-0 pb-12 rounded-b-lg right-2 absolute md:text-right px-9 transition-all duration-500 ease-in font-bold ${open ? 'top-20' : 'top-[-700px]'}`}>
          {LINKS?.map((item: any) =>
            <li className='md:ml-8 text-lg md:my-0 my-7' key={item.name}>
              <Link to={item.link} className="hover:text-gray-400 text-[#4E4E4E] duration-500">{item.name}</Link>
            </li>
          )}

          <div className='md:visible my-3 md:my-0'>
            <Link to="https://www.25thandstaffing.com/partner" className=''>
              <Button className='text-[#100F0F] border border-[#000000] hover:text-[black] hover:bg-white'>
                Make an Order
              </Button>
            </Link>
          </div>
        </ul>
      </div>
    </div>
  )
}

export default Navbar