import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification

// types and state
import { IGetReportFunc } from "../../store/types/report";
import { setLoading, setReportRole } from "../../store/slices/report_candidate";
import { IGetReportCandidate } from "../../store/types/report_candidate";

export const get_reports_orders: IGetReportFunc = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-report-orders/${id}`);

    dispatch(setReportRole(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_reports_order_candidates: IGetReportCandidate = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/get-report-order-candidates/${id}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
