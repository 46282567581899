// hr_management-yfbchj

// axios
import { isAxiosError } from 'axios'
import axiosInstance from '../../utility/axiosInstance';
import { setApplicants } from '../../store/slices/applicant';

type IGetJob = (dispatch: Function, id: string, page: number) => Promise<void>;
export const get_applicants: IGetJob = async (dispatch, id, page=1) => {
//   dispatch(setLoading())
  try {
    const response = await axiosInstance.get(`/get-applicants/${id}?page=${page}`)

    dispatch(setApplicants(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
//   dispatch(stopLoading())
};

