// axios
import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import {
  openNotification,
  errorNotification,
} from "../../components/ui/alerts";

// auth Types
import { IChangePasswordData, ILogin } from "../../store/types/auth";
import { setLoading } from "../../store/slices/loading";
import { setUser, Loading } from "../../store/slices/auth";
import { CreateNewUser } from "../../store/types/auth";
import handler from "../../constants/responseErrorHandler";

export const auth = async (formData: ILogin, dispatch: any, history: any) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/auth/sign-in", formData);

    const { data } = response;
    localStorage.setItem("@serial", data.serial);
    history("/");
  } catch (error) {
    handler(error);
    if (isAxiosError(error)) {
      errorNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    } else {
      errorNotification({
        status: "error",
        message: "Please check your Internet.",
      });
    }
  }
  dispatch(setLoading());
};

type userRegisterType = (
  dispatch: any,
  formData: CreateNewUser,
  navigate: any
) => Promise<void>;
export const userRegister: userRegisterType = async (
  dispatch,
  formData,
  navigate
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/sign-up", formData);

    if (response.data?.message === "This account already exist!") {
      throw new Error();
    } else {
      openNotification({ status: "success", message: response.data?.message });
    }
  } catch (error) {
    if (isAxiosError(error)) {
      errorNotification({
        status: "error",
        message: error?.response?.data?.message,
      });
    } else {
      errorNotification({
        status: "error",
        message: "This account already exists!",
      });
    }
  }
  dispatch(setLoading());
};

type getUserData = (dispatch: any) => Promise<void>;
export const get_profile: getUserData = async (dispatch) => {
  try {
    const response = await axiosInstance.get("/get-profile");

    dispatch(setUser(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

type IForgotPassword = (
  dispatch: any,
  formData: { email: string }
) => Promise<boolean>;
export const forgot_password: IForgotPassword = async (dispatch, formData) => {
  dispatch(setLoading());
  let result = false;
  try {
    await axiosInstance.post("/auth/forgot-password", formData);

    localStorage.setItem("@email", formData?.email);
    result = true;
  } catch (error) {
    result = false;
    handler(error);
  }
  dispatch(setLoading());
  return result;
};

type IChangePassword = (
  dispatch: Function,
  formData: IChangePasswordData,
  navigate: Function
) => Promise<void>;
export const change_password: IChangePassword = async (
  dispatch,
  formData,
  navigate
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post(
      "/auth/change-password",
      formData
    );

    if (response?.data?.message === "Please input the correct details.") {
      throw new Error("Please input the correct details.");
    } else {
      localStorage.removeItem("@email");
      openNotification({ status: "success", message: response?.data?.message });
      navigate("/login");
    }
  } catch (error) {
    handler(error);
    errorNotification({
      status: "error",
      message: "Incorrect Token provided.",
    });
  }
  dispatch(setLoading());
};

export const logout = async (dispatch: Function, navigation: Function) => {
  dispatch(Loading());
  try {
    await axiosInstance.get("/logout");
    localStorage.removeItem("@serial");
    navigation('/login')
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(Loading());
};
