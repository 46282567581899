import React from "react";

// components
import TextInput from "../../../components/elements/forms/TextInput";
import { Modal } from "antd";

// state
// import MiniButton from "../../../components/elements/forms/MiniButton";
import {
  IUpdatePassword,
  IUpdatePasswordError,
} from "../../../store/types/profile";
import { FaEye, FaEyeSlash } from "react-icons/fa";

type ICreateSoftSkillType = {
  title: string;
  visible: boolean;
  handleOk: (data: IUpdatePassword) => void;
};

const CreateModal: React.FC<ICreateSoftSkillType> = ({
  title,
  visible,
  handleOk,
}) => {
  const [show, showSet] = React.useState<boolean>(false);
  const [data, dataSet] = React.useState<IUpdatePassword>({
    old_password: "",
    password: "",
    password_confirmation: "",
  });
  const [err, errSet] = React.useState<IUpdatePasswordError>({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <Modal
      title={title}
      open={visible}
      closable={false}
      closeIcon={false}
      footer={[]}
    >
      <div className="flex items-center">
        <TextInput
          name="old_password"
          status={err.old_password}
          value={data.old_password}
          onChange={onChange}
          placeholder="Old Password"
          type="text"
          className="flex-1"
        />
      </div>

      <div className="flex items-center">
        <TextInput
          name="password"
          status={err.password}
          value={data.password}
          onChange={onChange}
          placeholder="New Password"
          type={show ? "text" : "password"}
          className="flex-1"
        />
        <div className="mx-2" onClick={() => showSet(!show)}>
          {!show ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
        </div>
      </div>

      <div className="my-3">
        <TextInput
          name="password_confirmation"
          status={err.password_confirmation}
          value={data.password_confirmation}
          onChange={onChange}
          placeholder="Confirm New Password"
          type="password"
        />
      </div>

      <div className="my-3 flex justify-end">
        <div className="flex w-[30%] justify-between">
          <div className="mx-1 w-[49%]">
            {/* <MiniButton
              title="Cancel"
              onHandler={() => {
                handleOk(data);
                onSetEmpty();
              }}
              backgroundColor="red"
              textColor="white"
            /> */}
          </div>
          <div className="w-[49%]">
            {/* <MiniButton
              title="Ok"
              onHandler={() => {
                if (!data.password) {
                  return errSet((prev) => ({ ...prev, password: "warning" }));
                }
                if (
                  !data.password_confirmation ||
                  data.password !== data.password_confirmation
                ) {
                  return errSet((prev) => ({
                    ...prev,
                    password_confirmation: "warning",
                  }));
                }
                const formData = data;
                handleOk(formData);
                onSetEmpty();
              }}
              backgroundColor="#243677"
              textColor="white"
            /> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateModal;
