import { configureStore } from "@reduxjs/toolkit";

// environment
import env from "../config/env";

// store
import auth from "./slices/auth";
import jobs from "./slices/jobs";
import misc from "./slices/misc";
import report from "./slices/report";
import loading from "./slices/loading";
import applicant from "./slices/applicant";
import report_candidate from "./slices/report_candidate";
import navbar from './slices/navbar';

export const store = configureStore({
  reducer: {
    auth,
    loading,
    jobs,
    report,
    applicant,
    report_candidate,
    misc,
    navbar,
  },
  devTools: env.enviroment === "production" ? false : true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
