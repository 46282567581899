import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification
import {
  openNotification,
  errorNotification,
} from "../../components/ui/alerts";

// types and state
import {
  IUpdatePasswordFunc,
  IUpdateProfFunction,
} from "../../store/types/profile";
import { setUpdate } from "../../store/slices/loading";
// import { notification } from "antd";
import { setUser } from "../../store/slices/auth";

type GetFullUserData = (dispatch: Function) => Promise<void>;
export const get_full_profile: GetFullUserData = async (dispatch) => {
  try {
    const response = await axiosInstance.get("/profile");

    dispatch(setUser(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const update_company_name: IUpdateProfFunction = async (
  dispatch,
  data
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-company-name", data);

    openNotification({ status: "success", message: response?.data?.message });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(setUpdate());
};

export const update_full_name: IUpdateProfFunction = async (dispatch, data) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-full-name", data);

    openNotification({ status: "success", message: response?.data?.message });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(setUpdate());
};

export const update_phone: IUpdateProfFunction = async (dispatch, data) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-phone-number", data);

    openNotification({ status: "success", message: response?.data?.message });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(setUpdate());
};

export const update_address: IUpdateProfFunction = async (dispatch, data) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-address", data);

    openNotification({ status: "success", message: response?.data?.message });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(setUpdate());
};

export const update_state: IUpdateProfFunction = async (dispatch, data) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-phone-number", data);

    openNotification({ status: "success", message: response?.data?.message });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(setUpdate());
};

export const update_staff_size: IUpdateProfFunction = async (
  dispatch,
  data
) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-staff-size", data);

    openNotification({ status: "success", message: response?.data?.message });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(setUpdate());
};

export const update_password: IUpdatePasswordFunc = async (dispatch, data) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.post("/update-phone-number", data);

    openNotification({ status: "success", message: response?.data?.message });
    get_full_profile(dispatch);
  } catch (error) {
    errorNotification({
      status: "error",
      message: "Please the necessary field",
    });
  }
  dispatch(setUpdate());
};
