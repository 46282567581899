import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";

// notification

// types and state
import {
  ICandidateCommentFunc,
  IGetReportFunc,
} from "../../store/types/report";
import {
  setLoading,
  setPreviousReport,
  setReport,
  setReportCandidate,
  setReportCandidates,
  setReportGenderStats,
  setReportLocStats,
  setReportRolePooled,
  setReportStat,
  setReports,
  setTotalReport,
} from "../../store/slices/report";
import { setUpdate } from "../../store/slices/loading";

export const get_reports: IGetReportFunc = async (dispatch, page) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-reports?page=${page}`);

    dispatch(setReports(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_past_reports: IGetReportFunc = async (dispatch, page) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-past-reports?page=${page}`);

    dispatch(setPreviousReport(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const get_report: IGetReportFunc = async (dispatch, id) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-report/${id}`);

    dispatch(setReport(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setLoading());
};

export const update_report_seen = async (id: string) => {
  try {
    await axiosInstance.get(`/update-report-seen/${id}`);
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const get_report_stats: IGetReportFunc = async (dispatch, id) => {
  try {
    const response = await axiosInstance.get(`/get-report-stats/${id}`);

    dispatch(setReportStat(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

export const get_report_candidates: IGetReportFunc = async (dispatch, id) => {
  try {
    const response = await axiosInstance.get(`/list-of-candidate/${id}`);

    dispatch(setReportCandidates(response.data?.report_role));
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

// obsolete
export const get_role_candidates: IGetReportFunc = async (dispatch, id) => {
  dispatch(setUpdate());
  try {
    const response = await axiosInstance.get(
      `/get-report-order-candidate/${id}`
    );

    dispatch(setReportCandidate(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
  dispatch(setUpdate());
};

export const client_comment_candidates: ICandidateCommentFunc = async (
  dispatch,
  data
) => {
  try {
    const response = await axiosInstance.post(
      `/client-comment-candidate`,
      data
    );

    console.log(response.data);

    // dispatch(setReportCandidate(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const get_report_roles: IGetReportFunc = async (dispatch, id) => {
  try {
    const response = await axiosInstance.get(
      `/report-roles-candidate-pooled/${id}`
    );

    dispatch(setReportRolePooled(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

export const get_report_gender_stats: IGetReportFunc = async (dispatch, id) => {
  try {
    const response = await axiosInstance.get(`/report-roles-gender/${id}`);

    dispatch(setReportGenderStats(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      // console.log(error?.response?.data);
    }
  }
};

export const get_report_loc_stats: IGetReportFunc = async (dispatch, id) => {
  try {
    const response = await axiosInstance.get(`/report-roles-location/${id}`);

    dispatch(setReportLocStats(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};

export const get_total_reports = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-total-report");

    dispatch(setTotalReport(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
    }
  }
};
