import React from "react";

// components
import ReactQuill from "react-quill";

// styles
import "react-quill/dist/quill.snow.css";

type TextInputProps = {
  placeholder: string;
  value: string;
  onChange?: (e: string) => void;
  status?: "warning" | "";
  label?: string;
  container?: string;
  required?: boolean;
};

const RichTextInputBox: React.FC<TextInputProps> = ({
  label,
  status,
  container,
  required,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <div className={`${container} my-5`}>
      {label && (
        <label
          className={`font-[600] py-1 font-abel ${status && "text-[red]"}`}
        >
          {label} {required && <span className="text-[red]">*</span>}
        </label>
      )}
      <ReactQuill
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="font-abel placeholder:font-abel h-[150px] md:h-[200px] md:mb-[50px] mb-[80px]"
      />
    </div>
  );
};

export default RichTextInputBox;
