import React from "react";

// components
import TextInput from "../../../components/elements/forms/TextInput";

type IInputUpdate = {
  label: string;
  name: string;
  onSubmit: (data: { name: string }) => void;
};

const InputUpdate: React.FC<IInputUpdate> = ({ label, name, onSubmit }) => {
  const [data, dataSet] = React.useState<{ name: string }>({
    name: "",
  });
  const [err, errSet] = React.useState<{ name: "warning" | "" }>({
    name: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet(() => ({ email: "", name: "" }));
  };

  const onLoad = React.useCallback(() => {
    if (name) dataSet((prev) => ({ ...prev, name }));
  }, [name]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <>
      <label className="text-[#333333] text-sm pb-4">{label}</label>
      <TextInput
        name="name"
        value={data.name}
        status={err.name}
        placeholder={label}
        onChange={onChange}
        onBlur={() => onSubmit(data)}
      />
    </>
  );
};

export default InputUpdate;
