import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INavButton, INavbar } from "../types/navbar";

// Define the initial state using that type
const initialState: INavbar = {
  search: "",
  title: "",
  button: false,
  button_name: "",
  action: false,
  search_visible: false,
  notification: "",
};

export const navbar = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSearchVisible: (state, action: PayloadAction<boolean>) => {
      state.search_visible = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setNavButton: (state, action: PayloadAction<INavButton>) => {
      state.button = action.payload.button;
      state.button_name = action.payload.button_name;
    },
    setButtonAction: (state) => {
      state.action = true;
    },
    setButtonActionFalse: (state) => {
      state.action = false;
    },
    setNotification: (state, action: PayloadAction<string>) => {
      state.notification = action.payload;
    },
  },
});

export const {
  setSearch,
  setTitle,
  setNavButton,
  setButtonAction,
  setButtonActionFalse,
  setNotification,
} = navbar.actions;

export default navbar.reducer;
