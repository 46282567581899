import React from "react";

// components
import CustomSelect from "../../../components/elements/forms/CustomSelect";
import { IDataType } from "../../../store/types/misc";

type IInputUpdate = {
  label: string;
  name: string;
  items: IDataType[];
  selected: string;
  onSelect: (data: { name: string }) => void;
};

const SelectUpdate: React.FC<IInputUpdate> = ({
  label,
  selected,
  items,
  onSelect,
}) => {
  return (
    <>
      <label className="text-[#333333] text-sm pb-4">{label}</label>

      <CustomSelect
        items={items}
        name="name"
        placeholder={label}
        status=""
        selected={selected}
        onSelect={(_name, value) => onSelect({ name: value })}
      />
    </>
  );
};

export default SelectUpdate;
